import { useState, useEffect } from 'react';
import { supabase } from '../supabase/supabaseClient';

import { ASSESSMENTS } from 'constants/databases';


function useAssessment (assessmentId, skillsDefinition) {
  const [loading, setLoading] = useState(false);
  const [assessment, setAssessment] = useState();
  const [selectedSkill, setSelectedSkill] = useState();
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  function convertAssessment(assessment){
    if(assessment?.progression?.tracks) {
      const skills = assessment.progression.tracks.map((track) => ({
        skillId: track.id,
        skillName: track.displayName,
        categoryId: track.streamId,
        categoryName: track.streamName,
        color: track.color,
        level: track.score,
        evidence: track.evidence
      }));

      return {
        ...assessment,
        progression: skills
      }; 
    } else{
      return assessment;
    };
  }

  useEffect(() => {
    if (assessmentId==="new") {
      const newAssessment = initAssessment(skillsDefinition);
      setAssessment(newAssessment);
    }
    else{
      loadAssessment(assessmentId);
    }
  }, [assessmentId, skillsDefinition]);


  function initAssessment(skillsDefinition) {
    const assessment = skillsDefinition?.map((t) => ({
      categoryId: t.streamId,
      categoryName: t.streamName,
      skillId: t.id,
      skillName: t.displayName,
      color: t.color,
      level:0
    }));

    return {progression: assessment};    
  }

  async function loadAssessment (assessmentId) {
    try {
      setLoading(true);
      const { data, error, status } = await supabase
        .from(ASSESSMENTS)
        .select('*')
        .eq('id', assessmentId);

      if (error && status !== 406) {
        throw error;
      }

      setAssessment(convertAssessment(data[0]));
      setSelectedSkill(assessment?.progression[0]);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  function selectSkill (skillId) {
    setSelectedSkill(assessment?.progression?.find((t) => t.skillId === skillId));
  }

  async function createAssessment (accountId, currentUser, status) {
    try {
      setSaving(true);
      setSuccess(false);
      const { data, error } = await supabase.from(ASSESSMENTS).insert(
        {
          account_id: accountId,
          user_id: currentUser.id,
          auth_id: currentUser.auth_id,
          created_by: currentUser.id,
          updated_by: currentUser.id,
          progression: assessment.progression,
          status: status
        }
      );

      if (error) {
        throw error;
      }

      setAssessment(data[0]);
      setSelectedSkill(assessment?.progression[0]);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } catch (error) {
      setError(error);
    } finally {
      setSaving(false);
    }
  }

  async function updateAssessment (currentUser, status) {
    try {
      setSaving(true);
      setSuccess(false);
      const { data, error } = await supabase.from(ASSESSMENTS).update(
        {
          ...assessment,
          updated_by: currentUser.id,
          status: status
        }
      ).eq('id', assessment.id);
      if (error) {
        throw error;
      }
      setAssessment(data[0]);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } catch (error) {
      setError(error);
    } finally {
      setSaving(false);
    }
  }

  async function saveAssessment (accountId, currentUser, status) {
   if(assessment?.id) {
     updateAssessment(currentUser, status);
   }
   else {
     createAssessment(accountId, currentUser, status);
   }
  }

  function updateEvidence (skillId, evidence) {
    selectSkill(skillId);
    const updatedAssessment = { ...assessment };
    const trackAssessment = updatedAssessment.progression.find((x) => x.skillId === skillId);
    trackAssessment.evidence = evidence;
    setAssessment(updatedAssessment);
  }

  function updateLevel (skillId, level) {
    selectSkill(skillId);
    const updatedAssessment = { ...assessment };
    const trackAssessment = updatedAssessment.progression.find((x) => x.skillId === skillId);
    if (level < 0) level = 0;
    if (level > 5) level = 5;
    trackAssessment.level = level;
    setAssessment(updatedAssessment);
  }
 

  return {
    assessment,
    selectedSkill,
    loading,
    saving,
    success,
    error,
    selectSkill,
    updateLevel,
    updateEvidence,
    saveAssessment
  };
}

export default useAssessment;
