import React from "react";
import Select from 'react-select';
import { Controller } from "react-hook-form";


const formSelectStyles = {
  control: base => ({
    ...base,
    padding: "0.28rem 0.75rem",
    color: "#8898aa"
  }),
  singleValue: base => ({
    ...base,
    color: "#8898aa"
  }),

};

const FormSelect = (props) => {
  const defaultVal = props.options?.find(option => option.value === props.defaultValue);
  return <Controller
        {...props}
        render={({ field: { onChange } }) => (
        <Select
            name={props.name}
            styles={formSelectStyles}
            options={props.options}
            defaultValue={defaultVal}
            isSearchable={true}
            onChange={(selectedOption) => {
              onChange(selectedOption.value);
            }}
        />
        )}
    />;
};

export default FormSelect;