import React from "react";

import { Card, CardBody, CardTitle } from "reactstrap";

function Notification({ title, message }) {
  return (
    <div style={{ padding: "150px" }}>
      <Card className="bg-primary p">
        <CardBody>
          <CardTitle className="text-white" tag="h3">
            {title}
          </CardTitle>
          <p className=" text-white mb-0">{message}</p>
        </CardBody>
      </Card>
    </div>
  );
}

export default Notification;
