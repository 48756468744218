import React, { useState } from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
import Header from "components/Headers/Header.js";
import { useAppContext } from "contexts/AppContext";

import TeamsAssessments from "./TeamsAssessments";
import MyStatus from "./MyStatus";
import EmployeeList from "./EmployeeList";
import useTeamDashboard from "hooks/useTeamDashboard";
import AssessmentRadarCard from "../Compare/AssessmentRadarCard";
//import MentoringCircles from "./MentoringCirclesList";

function HomePage() {
  const { user, account, loading } = useAppContext();
  const [onlyMyTeam, setOnlyMyTeam] = useState(true);

  const {
    users,
    loading: teamsLoading,
    error,
  } = useTeamDashboard(account?.id, user?.id, onlyMyTeam, true);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner> </Spinner>
      </div>
    );
  }
  const me = users?.find((u) => u.id === user.id);
  //filter me out of the list
  const teamMembers = users?.filter((u) => u.id !== user.id);
  return (
    <>
      <Header />
      <Container className="mt--1" fluid>
        <Row className="mt-3">
          <Col xs="12" md="8" className="pb-3">
            {user?.role === "admin" || (teamMembers && teamMembers.length > 0) ? (
              <EmployeeList
                {...{
                  users: teamMembers,
                  onlyMyTeam,
                  setOnlyMyTeam,
                  loading: teamsLoading,
                  error,
                  displayToggleAll: user?.role === "admin",
                }}
              />
            ) : (
              <AssessmentRadarCard
                user={{ accountId: account?.id, ...user }}
                title="My Assessment"
                displayClose={false}
                displayName={false}
                displayStatus={false}
              />
            )}
          </Col>
          <Col xs="12" md="4">
            <>
              {me && (
                <div className="pb-3">
                  <MyStatus user={me} />
                </div>
              )}
              {user?.role === "admin" && (
                <TeamsAssessments
                  title="Assessments to approve"
                  user={user}
                  account={account}
                  status="reviewed"
                  onlyMyTeam={false}
                />
              )}
              {/* <div className="mt-3">
                <MentoringCircles />
              </div> */}
            </>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default HomePage;
