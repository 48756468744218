import React from 'react';
import {
  ButtonGroup,
  Button
} from 'reactstrap';


export default function LinkControls({
  layout,
  setLayout,
}) {
  return (
    <div className='mt-3 ml-3'>
        <ButtonGroup aria-label="Graphe Type" role="group">
          <Button className={layout==='polar' ? "active" :""} color="secondary" type="button"
            onClick={(e) => setLayout('polar')}>
              <span className="btn-inner--icon"  >
               <i className="far fa-snowflake" />
            </span>
          </Button>
          <Button className={layout==='cartesian' ? "active" :""} color="secondary" type="button"
             onClick={(e) => setLayout('cartesian')}>
            <span className="btn-inner--icon">
              <i className="fas fa-code-branch fa-rotate-90" />
            </span>
          </Button>
        </ButtonGroup>
    </div>
  );
}