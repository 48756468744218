import React, { useState } from "react";
import {
  Button,
  Card,
  Spinner,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useParams } from "react-router-dom";

import Header from "components/Headers/Header.js";
import Notification from "components/Notification";
import Message from "components/Message";

import LevelRadarChart from "components/Charts/LevelRadarChart";
import NightingaleChart from "components/Charts/NightingaleChart";

import SkillSelector from "./SkillSelector";
import SkillLevelEditor from "./SkillLevelEditor";
import EvidenceEditor from "./EvidenceEditor";
import AssessmentTimeline from "./AssessmentTimeline";

import { useAppContext } from "contexts/AppContext";
import useFramework from "hooks/useFramework";
import useAssessment from "hooks/useAssessment";
import useProfile from "hooks/useProfile";

import { getTitleById } from "constants/data";

function AssessmentEditorPage() {
  const [editorExpanded, setEditorExpanded] = useState(false);
  const { id: assessmentId } = useParams();
  const { user, account, lang, loading: contextLoading } = useAppContext();
  const { tracks, loading: frameworkLoading } = useFramework(
    account?.id,
    user?.id,
    lang
  );

  const {
    assessment,
    selectedSkill,
    saving,
    success,
    error,
    selectSkill,
    updateLevel,
    updateEvidence,
    saveAssessment,
  } = useAssessment(assessmentId, tracks);

  const { profile } = useProfile(assessment?.user_id);

  const title = getTitleById(profile?.position);

  if (!user || contextLoading || frameworkLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner> </Spinner>
      </div>
    );
  }

  function toggleExpanded() {
    setEditorExpanded(!editorExpanded);
  }

  if (!contextLoading && !account) {
    return (
      <>
        <Header />
        <Notification
          title="You don't have an account"
          message="Please ask your organization admin to send you an invitation"
        />
      </>
    );
  }

  if (!assessment) {
    return null;
  }

  const canEdit =
    assessment?.status !== "reviewed" &&
    assessment?.status !== "approved" &&
    assessment?.status !== "archived";

  const canSubmit =
    assessment?.status === "draft" && user.id === assessment.user_id;

  const canReview =
    assessment?.status === "submitted" &&
    assessment.user_id &&
    user?.id !== assessment.user_id;

  const canApprove =
    user?.role === "admin" &&
    assessment?.status === "reviewed" &&
    assessment.user_id &&
    user?.id !== assessment.user_id;

  const canReturn =
    assessment?.status === "submitted" ||  assessment?.status === "reviewed" ||  assessment?.status === "approved" &&
    assessment.user_id &&
    user?.id !== assessment.user_id;

  return (
    <>
      <Header />
      <Message
        success={success && "Changes have been saved!"}
        error={error && "Oops. Something went wrong!"}
      />
      <Container className="mt--1" fluid>
        <AssessmentTimeline assessment={assessment} />
        <Card className="shadow p-3 mb-3">
          <Row>
            <Col className="mb-5 mb-xl-0" xl="6">
              <div
                className="border-0 card text-sm text-center p-3 mb-0"
                style={{ background: "#a5ccf426", minHeight: "450px" }}
              >
                <NightingaleChart skills={assessment?.progression} />
              </div>
            </Col>
            <Col xl="6">
              <Card
                className="border-0 text-sm text-center p-3 mb-0"
                style={{ background: "#a5ccf426", minHeight: "450px" }}
              >
                <LevelRadarChart
                  typicalProfile={title}
                  skillsAssessment={assessment?.progression}
                />
              </Card>
            </Col>
          </Row>
        </Card>
        <Card className="shadow">
          <CardHeader className="border-0">
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Impact Assessment</h3>
              </div>
              <div className="col text-right">
                {canEdit && (
                  <Button
                    color="default"
                    disabled={saving}
                    onClick={(e) => {
                      e.preventDefault();
                      saveAssessment(account?.id, user, "draft");
                    }}
                    size="m"
                    outline
                  >
                    Save
                  </Button>
                )}
                {canSubmit && (
                  <Button
                    color="success"
                    disabled={saving}
                    onClick={(e) => {
                      e.preventDefault();
                      saveAssessment(account?.id, user, "submitted");
                    }}
                    size="m"
                  >
                    Send to manager
                  </Button>
                )}
                {canReturn && (
                  <Button
                    color="default"
                    disabled={saving}
                    onClick={(e) => {
                      e.preventDefault();
                      saveAssessment(account?.id, user, "draft");
                    }}
                    size="m"
                  >
                    Return
                  </Button>
                )}
                {canReview && (
                  <Button
                    color="success"
                    disabled={saving}
                    onClick={(e) => {
                      e.preventDefault();
                      saveAssessment(account?.id, user, "reviewed");
                    }}
                    size="m"
                  >
                    Send to approval
                  </Button>
                )}
                {canApprove && (
                  <Button
                    color="success"
                    href="#submit"
                    disabled={saving}
                    onClick={(e) => {
                      e.preventDefault();
                      saveAssessment(account?.id, user, "approved");
                    }}
                    size="m"
                  >
                    Approve
                  </Button>
                )}
              </div>
            </Row>
            <CardBody>
              <Row>
                <SkillSelector
                  progression={assessment.progression}
                  selectedSkill={selectedSkill}
                  handleSelectSkill={selectSkill}
                />
              </Row>
              <Row>
                <Col xs={editorExpanded ? "2" : "8"}>
                  <SkillLevelEditor
                    skillDefintion={tracks?.find(
                      (t) => t?.id === selectedSkill?.skillId
                    )}
                    currentLevel={selectedSkill?.level}
                    updateLevel={updateLevel}
                  />
                </Col>
                <Col xs={editorExpanded ? "10" : "4"}>
                  {selectedSkill && (
                    <EvidenceEditor
                      content={selectedSkill?.evidence}
                      expanded={editorExpanded}
                      readOnly={
                        assessment.user_id && assessment.user_id !== user?.id
                      }
                      onChange={(content) =>
                        updateEvidence(selectedSkill?.skillId, content)
                      }
                      onExpand={toggleExpanded}
                    />
                  )}
                </Col>
              </Row>
            </CardBody>
          </CardHeader>
        </Card>
      </Container>
    </>
  );
}

export default AssessmentEditorPage;
