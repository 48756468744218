import React from "react";
import { Row, Col } from "reactstrap";

function AssessmentTimeline({ assessment }) {
  const steps = [
    {
      code: "selfAssess",
      label: "Self Assessment",
      activeStatus: "draft",
      completedStatus: ["submitted", "reviewed", "approved"],
    },
    {
      code: "managerReview",
      label: "Manager Review",
      activeStatus: "submitted",
      completedStatus: ["reviewed", "approved"],
    },
    {
      code: "commiteeReview",
      label: "Commitee Review",
      activeStatus: "reviewed",
      completedStatus: "approved",
    },
  ];

  const stepList = steps.map((step) => {
    const status = assessment.status;
    const isActive = step.activeStatus === status;
    const isCompleted = step.completedStatus.includes(status);
    const color = isCompleted
      ? "badge-success"
      : isActive
      ? "badge-info"
      : "bg-light";
    const icon = isCompleted
      ? "ni ni-check-bold"
      : isActive
      ? "ni ni-watch-time"
      : "ni ni-fat-remove";
    return {
      ...step,
      color,
      icon,
      isActive,
      isCompleted,
    };
  });

  return (
    <>
      <style>{`
          .background-line {
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(233,233,239,1) 51%, rgba(255,255,255,0) 57%, rgba(255,255,255,0) 100%);
          }
        `}</style>
      <Row className="background-line mb-3 text-center">
        {stepList.map((step) => {
          return (
            <Col key={step.code}>
              <span className={"badge-lg badge text-capitalize " + step.color}>
                <i className={"mr-1 " + step.icon} />
                {step.label}
              </span>
            </Col>
          );
        })}
      </Row>
    </>
  );
}

export default AssessmentTimeline;
