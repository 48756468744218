import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import ParentSize from "@visx/responsive/lib/components/ParentSize";

import Header from "components/Headers/Header.js";

import AccountSidebar from "./AccountSidebar";
import ManagersTree from "./ManagersGraph/ManagersTree";


import { useAppContext } from '../../contexts/AppContext';
import useMembers from "hooks/useMembers";


function Managers() {

  const { user, account } = useAppContext();
  const { users } = useMembers(account?.id);

  if (!account || !users) {
    return null;
  }

  const usersToTreeData = (list = []) => {
    let map = {}, node, res = [], i;
    for (i = 0; i < list.length; i += 1) {
       map[list[i].id] = i;
       list[i].children = [];
    };
    for (i = 0; i < list.length; i += 1) {
       node = list[i];
       if (node.manager && list[map[node.manager]]) {
        list[map[node.manager]].children.push(node);
       }
       else {
          res.push(node);
       };
    };
    return res;
  };

  const graphData = users?.filter(u => u.code).map(user => ({
    id: user.id,
    name: user.code,
    displayName: user.name,
    manager: user.manager,
  }));

  let data = usersToTreeData(graphData)[0];

  return (
    <>
      <Header />
      <Container fluid>
        <Row>
          <Col xl="3">
            <AccountSidebar company={account} />
          </Col>
          <Col className="mb-5 mb-xl-0" xl="9">
            <Card
              className="shadow"
              style={{ height: "60em" }}
            >
              {data && 
                <ParentSize>
                  {({ width, height }) => (
                    <ManagersTree
                      width={width}
                      height={height}
                      data={data}
                      users={users}
                      currentUser={user.id}
                    />
                  )}
                </ParentSize>
              }
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Managers;
