import React, { useState } from "react";
import { Progress } from "reactstrap";

const ActionsProgress = ({ actions }) => {
  const total =
    actions?.todo?.length + actions?.doing?.length + actions?.done?.length;
  return (
    <div>
      <div>
        <Progress multi style={{ height: 10, fontSize: "0.5rem" }}>
          {total > 0 && (
            <>
              <Progress
                bar
                color="success"
                max={total}
                value={actions?.done?.length}
              >
                {actions?.done?.length} Done
              </Progress>
              <Progress
                bar
                color="warning"
                max={total}
                value={actions?.doing?.length}
              >
                {actions?.doing?.length} Doing
              </Progress>
              <Progress
                bar
                color="info"
                max={total}
                value={actions?.todo?.length}
              >
                {actions?.todo?.length} Todo
              </Progress>
            </>
          )}
        </Progress>
      </div>
    </div>
  );
};

export default ActionsProgress;
