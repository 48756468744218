import React from "react";

import {
  Card,
  Button,
  CardHeader,
  Media,
  Table,
  Row,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";

import useTeamAssessments from "./useTeamAssessments";

export default function TeamsAssessments({ title, user, account, status, onlyMyTeam }) {
  const { assessments, loading, error } = useTeamAssessments(account?.id, user, status, onlyMyTeam);

  if (loading) {
    return (
      <Card className="shadow">
        <div className="d-flex justify-content-center p-9">
          <Spinner> </Spinner>
        </div>
      </Card>
    );
  }
  if (error) {
    return (
      <Card className="shadow">
        <div className="text-center justify-content-center ml-9 mr-9">
          <UncontrolledAlert color="danger" closeClassName="notif-close-btn">
            <span className="alert-text ml-1">{error}</span>
          </UncontrolledAlert>
        </div>
      </Card>
    );
  }
  return (
    <Card className="shadow mb-3">
      <CardHeader>
        <Row className="align-items-center">
          <div className="col">
            <h3 className="mb-0">{title}</h3>
          </div>
        </Row>
      </CardHeader>
      {assessments.length > 0 ? (
        <Table className="align-items-center table-flush" responsive>
          <tbody>
            {assessments.map(function (assessment) {
              return (
                <tr key={assessment.id}>
                  <th scope="row">
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle bg-purple text-xs">
                        {assessment.users?.code}
                      </span>
                      <div className="col ml--2">
                        <h4 className="mb-0">
                          <span className="ml-2 text-sm">
                            {assessment.users?.name}
                          </span>
                        </h4>
                        <small className="ml-2">
                          {" "}
                          {moment(assessment.updated_at).format("llll")}
                        </small>
                      </div>
                    </Media>
                  </th>
                  <td className="text-right">
                    <Button
                      color="primary"
                      to={"/app/assessment/" + assessment.id}
                      tag={Link}
                      size="sm"
                    >
                      View
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="text-center">
          <img alt="..." src={require("assets/img/icons/empty.png")} />
        </div>
      )}
    </Card>
  );
}
