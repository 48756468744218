import React, { useState } from 'react';
import {
  Button,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  InputGroupText,
  InputGroup,
  ListGroup,
  ListGroupItem,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';

function validateEmail (value) {
  return (value && value.length > 0 && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value));
}

const InviteMembers = ({ userId, inviteUsers }) => {
  const [modal, setModal] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const toggle = () => { setSuccess(false); setError(false); setModal(!modal); };

  const handleAddInvitation = (e) => {
    if (e.key === 'Enter') {
      const invitationsList = e.target.value.split(',')
        .filter(item => validateEmail(item))
        .map(email => ({ email: email, role: 'member' }));

      const filteredList = [
        ...new Map([...invitationsList, ...invitations].map(obj => [`${obj.email}:${obj.email}`, obj]))
          .values()
      ];

      setInvitations([...filteredList]);
      e.target.value = e.target.value.split(',').filter(item => !validateEmail(item));
      e.preventDefault();
    }
  };

  const updateInvitRole = (invit, role) => {
    invit.role = role;
  };

  const handleRemoveInvitation = (invit) => {
    setInvitations(invitations.filter(item => item.email !== invit.email));
  };

  const handleCancel = () => {
    toggle();
    setInvitations([]);
  };

  const handleSubmit = () => {
    inviteUsers(userId, invitations).then(function () {
      setSuccess(true);
      setInvitations([]);
    }).catch(err => {
        setError(err);
      });
  };

  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      &times;
    </button>
  );

  return (
    <div>
      <Button color="primary" type="button" onClick={toggle}>Add members</Button>
        <Modal isOpen={modal} toggle={toggle} size="lg">
          <ModalHeader toggle={toggle} close={closeBtn} tag="h3">
            Add Members
          </ModalHeader>
          <ModalBody style={{ minHeight: '70vh' }}>
            {
              success &&
                <SuccessNotification />
            }
            {
              error &&
                <ErrorNotification error={error}/>
            }
            {
            !(success || error) &&
              <>
              <Form className="pb-4">
                <FormGroup>
                  <label className="form-control-label">
                    Emails
                  </label>
                  <Input
                    placeholder="name1@example.com, name2@example.com"
                    type="email"
                    onKeyPress={handleAddInvitation}
                  /> 
                  <span className="text-sm">Please separate multiple emails with commas and press Enter to add users.</span>
                </FormGroup>
            </Form>

            <ListGroup className="list my--3" flush>
            {
              invitations.map(function (invit, i) {
                return <ListGroupItem key={i} className="px-0">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <span className="avatar avatar-sm">
                              <i className="ni ni-email-83" />
                        </span>
                      </Col>
                      <div className="col ml--2">
                        <h4 className="mb-0">
                            {invit.email}
                        </h4>
                      </div>
                      <Col>
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="nav-link small" >
                        <span className="pr-2"> {invit.role === 'admin' ? 'Administrator' : 'Member'}</span>
                        <i className="fas fa-angle-down"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          href="#"
                          onClick={e => updateInvitRole(invit, 'member')}
                        >
                          Member
                        </DropdownItem>
                        <DropdownItem
                          href="#"
                          onClick={e => updateInvitRole(invit, 'admin')}
                        >
                          Administrator
                        </DropdownItem>
                      </DropdownMenu>
                      </UncontrolledDropdown>
                      </Col>
                      <Col className="col-auto">
                        <Button className="btn-icon" outline type="button"
                          onClick={e => handleRemoveInvitation(invit)} >
                          <span className="btn-inner--icon">
                            <i className="far fa-trash-alt" />
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>;
              })
              }
              </ListGroup>
              </>
            }
          </ModalBody>
          <ModalFooter>
            {
                success
                  ? <Button color="secondary" onClick={handleCancel}>Close</Button>
                  : <>
                  <Button color="secondary" onClick={handleCancel}>Cancel</Button>
                  <Button color="primary" onClick={handleSubmit} disabled={invitations.length === 0}>Add ({invitations.length})</Button>
                </>
            }

          </ModalFooter>
        </Modal>
    </div>
  );
};

const SuccessNotification = () => (
  <div className="text-center pt-7">
      <div className="badge-circle icon-xl text-success">
        <i className="far fa-check-circle fa-7x"></i>
      </div>
      <div className="font-weight-bold mt-3">Invitations sent</div>
  </div>
);

const ErrorNotification = () => (
  <div className="text-center pt-7">
    <div className="badge-circle icon-xl text-danger">
      <i className="far fa-times-circle fa-7x"></i>
    </div>
    <div className="font-weight-bold mt-3">Error while sending invitations</div>
</div>
);

export default InviteMembers;
