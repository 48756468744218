import React, { useState, useCallback } from "react";
import { Group } from "@visx/group";
import { hierarchy, Tree } from "@visx/hierarchy";
import { LinearGradient } from "@visx/gradient";
import { useTooltip } from "@visx/tooltip";
import { pointRadial } from "d3-shape";
import useForceUpdate from "./useForceUpdate";
import LinkControls from "./LinkControls";
import getLinkComponent from "./getLinkComponent";

import UserTooltip from "./UserTooltip";

const defaultMargin = { top: 20, left: 20, right: 20, bottom: 20 };

let tooltipTimeout;

export default function ManagersTree({
  users,
  data,
  currentUser,
  width: totalWidth,
  height: totalHeight,
  margin = defaultMargin,
}) {
  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip();

  const [layout, setLayout] = useState("polar");
  const [orientation, setOrientation] = useState("horizontal");
  const [linkType, setLinkType] = useState("diagonal");
  const [stepPercent, setStepPercent] = useState(0.5);
  const forceUpdate = useForceUpdate();

  const innerWidth = totalWidth - margin.left - margin.right;
  const innerHeight = totalHeight - margin.top - margin.bottom;

  if (!users) return null;

  let origin = {};
  let sizeWidth;
  let sizeHeight;

  if (layout === "polar") {
    origin = {
      x: innerWidth / 2,
      y: innerHeight / 2,
    };
    sizeWidth = 2 * Math.PI;
    sizeHeight = Math.min(innerWidth, innerHeight) / 2;
  } else {
    origin = { x: 0, y: 0 };
    if (orientation === "vertical") {
      sizeWidth = innerWidth;
      sizeHeight = innerHeight;
    } else {
      sizeWidth = innerHeight;
      sizeHeight = innerWidth;
    }
  }

  const LinkComponent = getLinkComponent({ layout, linkType, orientation });

  const handleMouseEnter = useCallback(
    (event) => {
      if (tooltipTimeout) clearTimeout(tooltipTimeout);
      showTooltip({
        tooltipLeft: event.nativeEvent.layerX,
        tooltipTop: event.nativeEvent.layerY,
        tooltipData: users?.filter((u) => u.id === event.target.id)[0],
      });
    },
    [showTooltip, users]
  );

  const handleMouseLeave = useCallback(() => {
    tooltipTimeout = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  }, [hideTooltip]);

  return totalWidth < 10 ? null : (
    <>
      <LinkControls
        layout={layout}
        orientation={orientation}
        linkType={linkType}
        stepPercent={stepPercent}
        setLayout={setLayout}
        setOrientation={setOrientation}
        setLinkType={setLinkType}
        setStepPercent={setStepPercent}
      />

      <div className="relative">
        {tooltipOpen &&
          tooltipData &&
          tooltipLeft != null &&
          tooltipTop != null && (
            <UserTooltip
              tooltipData={tooltipData}
              tooltipLeft={tooltipLeft}
              tooltipTop={tooltipTop}
            />
          )}
        <svg width={totalWidth} height={totalHeight}>
          <LinearGradient id="links-gradient" from="#fd9b93" to="#fe6e9e" />
          <rect
            width={totalWidth}
            height={totalHeight}
            rx={14}
            fill="#fff"
          />
          <g>
            <Group top={margin.top} left={margin.left}>
              <Tree
                root={hierarchy(data, (d) =>
                  d.isExpanded ? null : d.children
                )}
                size={[sizeWidth, sizeHeight]}
                separation={(a, b) =>
                  (a.parent === b.parent ? 1 : 0.5) / a.depth
                }
              >
                {(tree) => (
                  <Group top={origin.y} left={origin.x}>
                    {tree.links().map((link, i) => (
                      <LinkComponent
                        key={i}
                        data={link}
                        percent={stepPercent}
                        stroke="#ced4da"
                        strokeWidth="1"
                        fill="none"
                      />
                    ))}

                    {tree.descendants().map((node, key) => {
                      const width = 40;
                      const height = 20;

                      let top;
                      let left;
                      if (layout === "polar") {
                        const [radialX, radialY] = pointRadial(node.x, node.y);
                        top = radialY;
                        left = radialX;
                      } else if (orientation === "vertical") {
                        top = node.y;
                        left = node.x;
                      } else {
                        top = node.x;
                        left = node.y;
                      }

                      return (
                        <Group top={top} left={left} key={key}>
                          <rect
                            id={node.data.id}
                            height={height}
                            width={width}
                            y={-height / 2}
                            x={-width / 2}
                            fill={
                              node.data.id === currentUser
                                ? "#2dce89"
                                : "#7b8adf"
                            }
                            strokeWidth={1}
                            strokeDasharray={
                              node.data.children && node.data.children.length
                                ? "0"
                                : "2,2"
                            }
                            strokeOpacity={
                              node.data.children && node.data.children.length
                                ? 1
                                : 0.6
                            }
                            rx={10}
                            onClick={() => {
                              node.data.isExpanded = !node.data.isExpanded;
                              forceUpdate();
                            }}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onTouchMove={handleMouseEnter}
                            onTouchEnd={handleMouseLeave}
                          />

                          <text
                            dy=".33em"
                            fontSize={9}
                            fontFamily="Arial"
                            textAnchor="middle"
                            style={{ pointerEvents: "none" }}
                            fill={
                              node.depth === 0
                                ? "#fff"
                                : node.children && node.children.length
                                ? "#fff"
                                : "#fff"
                            }
                          >
                            {node.data.name}
                          </text>
                        </Group>
                      );
                    })}
                  </Group>
                )}
              </Tree>
            </Group>
          </g>
        </svg>
      </div>
    </>
  );
}
