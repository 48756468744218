import React from "react";
import {
  Button,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Spinner,
  UncontrolledAlert,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";

import StatusBadge from "components/StatusBage";

import * as PATHS from "constants/paths";
import ActionsProgress from "./ActionsProgress";
import GoalStatus from "./GoalStatus";

export default function EmployeeList({
  users,
  loading,
  error,
  onlyMyTeam,
  setOnlyMyTeam,
  displayToggleAll,
}) {
  if (error) {
    return (
      <Card className="shadow">
        <div className="text-center justify-content-center ml-9 mr-9">
          <UncontrolledAlert color="danger" closeClassName="notif-close-btn">
            <span className="alert-text ml-1">{error}</span>
          </UncontrolledAlert>
        </div>
      </Card>
    );
  }

  if (users) {
    return (
      <EmployeeListCmp
        employeeList={users}
        onlyMyTeam={onlyMyTeam}
        setOnlyMyTeam={setOnlyMyTeam}
        loading={loading}
        displayToggleAll={displayToggleAll}
      />
    );
  }

  return null;
}

const EmployeeListCmp = ({
  employeeList,
  onlyMyTeam,
  setOnlyMyTeam,
  loading,
  displayToggleAll,
}) => (
  <Card className="shadow">
    <CardHeader className="border-0">
      <Row>
        <Col xs="6">
          <h3 className="mb-0">My Team</h3>
        </Col>
        {displayToggleAll && (
          <Col xs="4" className="text-right">
            <div className="d-flex flex-row-reverse">
              <label className="custom-toggle custom-toggle-primary">
                <input
                  type="checkbox"
                  checked={!onlyMyTeam}
                  onChange={() => setOnlyMyTeam(!onlyMyTeam)}
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="OFF"
                  data-label-on="ON"
                ></span>
              </label>
              <span className="text-sm mr-2">All employees</span>
            </div>
          </Col>
        )}
        <Col xs="2" className="text-right">
          <Button color="secondary" to={PATHS.COMPARE_PATH} tag={Link} size="sm">
            Compare
          </Button>
        </Col>
      </Row>
    </CardHeader>
    {loading ? (
      <div className="d-flex justify-content-center">
        <Spinner> </Spinner>
      </div>
    ) : (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Assessment Status</th>
            <th scope="col">Goals Definition</th>
            <th scope="col">Plan Completion</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {employeeList.map(function (employee, i) {
            return (
              <tr key={employee.id}>
                <th scope="row">
                  <div className="row align-items-center">
                    <span className="avatar avatar-sm rounded-circle bg-purple text-xs">
                      {employee?.code}
                    </span>
                    <div>
                      <h4 className="mb-0 ml-2 text-sm">
                        {" "}
                        {employee.name || employee.email}
                      </h4>
                    </div>
                  </div>
                </th>
                <td>
                  <StatusBadge status={employee.assessments[0]?.status} />
                </td>
                <td>
                  <GoalStatus goal={employee.plans[0]?.goal} />
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div>
                      <ActionsProgress actions={employee.plans[0]?.actions} />
                    </div>
                  </div>
                </td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      href="#"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" end>
                      <DropdownItem
                        href="#"
                        tag={Link}
                        to={`${PATHS.ASSESSMENTS_PATH}/${employee.assessments[0]?.id}`}
                      >
                        View Assessment
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        tag={Link}
                        to={`${PATHS.PLAN_PATH}/${employee?.id}`}
                      >
                        View Plan
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        tag={Link}
                        to={`${PATHS.USER_PROFILE_PATH}/${employee?.id}`}
                      >
                        View Profile
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    )}
  </Card>
);
