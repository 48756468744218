import { useState, useEffect } from "react";
import { supabase } from "supabase/supabaseClient";
import { ASSESSMENTS } from "constants/databases";

function useTeamAssessments(accountId, user, assessmentStatus, onlyMyTeam) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [assessments, setAssessments] = useState(null);

  useEffect(() => {
    if (accountId && user?.id) {
      loadAssessments();
    }
  }, [accountId, user?.id, assessmentStatus]);

  async function loadAssessments() {
    try {
      setLoading(true);

      let query = supabase
      .from(ASSESSMENTS)
      .select(
        `id, updated_at, status,
        users!assessments_user_id_fkey(id, name, email, code)`,
      )
      .eq("account_id", accountId)
      .neq("user_id", user?.id)
      .order('updated_at', { ascending: false });
      
      if (assessmentStatus) { 
        query = query.eq("status", assessmentStatus);
      }

      if (onlyMyTeam) {
        query = query.eq("users.manager", user.id);
      }
      const { data, error, status } = await query;
      
      if (error && status !== 406) {
        throw error;
      }


      if (data) {
        setAssessments(data.filter(assessment => assessment.users));
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  return { assessments, loading, error };
}

export default useTeamAssessments;
