import React from "react";

import {
  Card,
  Button,
  CardHeader,
  Col,
  CardBody,
  Row,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";

import NightingaleChart from "components/Charts/NightingaleChart";
import StatusBadge from "components/StatusBage";
import useMyLevel from "hooks/useMyLevel";

export default function AssessmentRadarCard({
  user,
  title,
  displayClose = true,
  displayName = true,
  displayStatus = true,
  onClose = () => {},
}) {
  const { assessment, loading, error } = useMyLevel(user?.accountId, user?.id);

  const cardTitle = displayName ? ( title ? `${user?.name} - ${title}` : user?.name) : title;

  if (loading) {
    return (
      <Card className="shadow">
        <div className="d-flex justify-content-center p-9">
          <Spinner> </Spinner>
        </div>
      </Card>
    );
  }
  if (error) {
    return (
      <Card className="shadow">
        <div className="text-center justify-content-center ml-9 mr-9">
          <UncontrolledAlert color="danger" closeClassName="notif-close-btn">
            <span className="alert-text ml-1">{error}</span>
          </UncontrolledAlert>
        </div>
      </Card>
    );
  }
  return (
    <Card className="bg- border-0">
      <CardHeader className="bg-transparent border-0 pb-0">
        <Row className="align-items-center">
          <Col xs="10">
            <div className="d-flex">
              <h3 className="mb-0 mr-2">{cardTitle}</h3>
              {displayStatus && (
                <StatusBadge status={assessment?.status} onlyDraft={true} />
              )}
            </div>
          </Col>
          <Col className="text-right">
            {displayClose && (
              <Button
                color="default"
                outline
                className="border-0"
                onClick={() => onClose(user)}
                size="sm"
              >
                <i className="fas fa-xmark text-muted" />
              </Button>
            )}
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          {assessment ? (
            <Col>
              <Card
                className="border-0 text-sm text-center p-3 mb-0"
                style={{ background: "#a5ccf426", minHeight: "450px" }}
              >
                <NightingaleChart skills={assessment?.progression} />
              </Card>
            </Col>
          ) : (
            <Col className="text-center">
              <div className="p-5">
                <i className="fa-solid fa-ban fa-2xl" />
                <h3 className="mt-3">No assessment found</h3>
              </div>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
}
