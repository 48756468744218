import React from "react";
import { Card, CardTitle, Col, CardBody, Row } from "reactstrap";

function StreamCard({ stream }) {
  if (!stream) {
    return null;
  }
  return (
    <Card className="shadow" style={{ minHeight: "280px" }}>
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle className="mb-0">
              <h3 style={{ color: stream.color }}>{stream.name}</h3>
            </CardTitle>
          </div>
          <Col className="col-auto">
            <div
              className="icon icon-shape text-white rounded-circle shadow"
              style={{ background: stream.color }}
            >
              {stream.name.charAt(0)}
            </div>
          </Col>
        </Row>
        <p className="mt-3 mb-0 text-sm">
          <span>{stream.description}</span>
        </p>
      </CardBody>
    </Card>
  );
}

export default StreamCard;
