import React from 'react';

export const CompanyAvatar = ({ name, photo }) =>

  photo
    ? (
          <span className="avatar avatar-sm">
             <img
               alt="..."
               src={photo}
             />
           </span>
      )
    : (
      <div className="badge-lg badge badge-success p-3">
        <span style={{ fontSize: '1rem' }}>
          {name && name
            .split(' ')
            .map(x => x.charAt(0))
            .join('')
            .substr(0, 1)
            .toUpperCase()
            }
        </span>
      </div>
      );
