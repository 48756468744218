import React from "react";
import Radar from "react-d3-radar";

export function total(category) {
  return category
    .map((i) => i.level)
    .reduce(function (a, b) {
      return a + b;
    });
}

function LevelRadarChart({ typicalProfile, skillsAssessment }) {
  if (!typicalProfile && !skillsAssessment) {
    return null;
  }

  let categories=[]; 
  if(typicalProfile && typicalProfile.radar){
    categories = Object.keys(typicalProfile.radar).map((category) => ({
      key: category,
      label: category,
    }));
  } else if(skillsAssessment){
     const groups = skillsAssessment.reduce(
      (groups, item) => ({
        ...groups,
        [item.categoryName]: [...(groups[item.categoryName] || []), item],
      }),
      {}
    );

     categories = Object.keys(groups).map((category) => ({
      key: category,
      label: category,
    }));
  }
  

  const assessmentLevels = skillsAssessment?.reduce(
    (groups, item) => ({
      ...groups,
      [item.categoryName]: [...(groups[item.categoryName] || []), item],
    }),
    {}
  );

  let values = {};
  categories.forEach(function (category) {
    values[category.key] = assessmentLevels
      ? total(assessmentLevels[category.key])
      : 0;
  });

  const chartData = {
    variables: categories,
    sets: [
      {
        key: "reference",
        label: "Typical profile",
        color: "#1f77b4",
        values: typicalProfile?.radar,
      },
      {
        key: "me",
        label: "Me",
        color: "#ff7f0e",
        values: values,
      },
    ],
  };

  return (
    <div>
      <div>
        {chartData.sets?.map(function (t) {
          return (
            <span
              key={t.key}
              className="badge badge-xs mr-1 text-white"
              style={{
                borderRadius: ".15rem",
                opacity: "70%",
                background: t.color,
              }}
            >
              {t.label}
            </span>
          );
        })}
      </div>
      <Radar
        width={400}
        height={400}
        padding={60}
        domainMax={15}
        style={{ fontSize: "0.4rem" }}
        data={chartData}
      />
    </div>
  );
}

export default LevelRadarChart;
