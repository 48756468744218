import React, { useState } from 'react';

import {
  Badge,
  Card,
  Button,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Row
} from 'reactstrap';
import {
  Link
} from 'react-router-dom';

// import { PROGRESSION_PATH } from 'constants/paths';

import { UserAvatar } from 'components/Avatars/UserAvatar';
import InviteMembers from './InviteMembers';

import useMembers from 'hooks/useMembers';

export default function AccountMembers ({ user, account }) {

  const handleDelete = (member) => {
  };

  const handleChangeRole = (member, role) => {
  };

  const { users, inviteUsers } = useMembers(account?.id);
  const hasMore = !users;
  const loading = !users;
  if (users) {
    return (
      <Card className="shadow">
          <CardHeader className="border-0">
            <Row className="align-items-center">
              <div className="col d-flex">
                <h2 className="mb-0">Members</h2>
                
              </div>
              <div className="col text-right">
                {
                  (user?.role === 'admin') &&
                    <InviteMembers accountId={account?.id} userId={user.id} inviteUsers={inviteUsers}/>
                }

              </div>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Role</th>
                <th scope="col"></th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
            {
              users.map(function (member, i) {
                return <tr key={i}>
                  <th scope="row">
                    <Media className="align-items-center">
                      {
                        member.status === 'pending'
                          ? <span className="avatar avatar-sm">
                            <i className="ni ni-email-83" />
                          </span>
                          : <UserAvatar displayName={member.name} photoUrl={member.avatar} />
                      }
                      <div className="col ml--2">
                        <h4 className="mb-0">
                        <span className="ml-2 text-sm">
                          {member.name || member.email}
                        </span>
                        </h4>
                        <small className="ml-2"> {member.email}</small>
                      </div>
                    </Media>
                  </th>
                  <td>
                    <div className="d-flex align-items-center">
                      <span className="mr-2">{member.role === 'admin' ? 'Administrator' : 'Member'}</span>
                    </div>
                  </td>
                  <td>
                    <Badge color="" className="badge-dot mr-4">
                      {
                        member.status === 'pending'
                          ? <>
                        <i className="bg-warning" />
                          <small className="text-muted font-italic">Invitation pending</small>
                        </>
                          : ''
                      }
                    </Badge>
                  </td>
                  <td className="text-right">
                    {
                      (user?.role === 'admin') &&
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-h" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                          <DropdownItem
                              href="#"
                            >
                              <Link to={'/app/progression/' + member.id}>View Progression</Link>
                            </DropdownItem>
                            <DropdownItem header tag="div" className="text-light">Change role to...</DropdownItem>
                            <DropdownItem
                              href="#"
                              onClick={() => handleChangeRole(member, 'admin')}
                            >
                              Administrator
                            </DropdownItem>
                            <DropdownItem
                              href="#"
                              onClick={() => handleChangeRole(member, 'member')}
                            >
                              Member
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem
                              href="#"
                              className="text-danger"
                              onClick={() => handleDelete(member)}
                            >
                              Remove from account
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                    }
                  </td>
                </tr>;
              })
          }

            </tbody>
          </Table>
          <CardFooter className="py-4 text-center">
            <nav aria-label="...">
              {hasMore && <Button active>
                {loading
                  ? <i className="fas fa-circle-notch fa-spin" />
                  : <i className="fas fa-arrow-down" />}
                <span className="pr-2">Load more...</span>
              </Button>}
            </nav>
          </CardFooter>
        </Card>

    );
  }

  return null;
}
