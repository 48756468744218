export const PRIVATE = '/app';
export const PUBLIC = '/public';

export const INDEX = '/index';
export const ACCOUNT_COMPANY = '/account/company';
export const ACCOUNT_USERS = '/account/users';
export const MANAGERS = '/account/managers';
export const TEAMS = '/teams';
export const TEAM = '/team';
export const MEETING = '/meeting';
export const PROFILE = '/profile';
export const USER_PROFILE = '/user-profile/:id';
export const LOGIN = '/login';
export const DASHBOARD = '/dashboard';
export const FRAMEWORK = '/framework';
export const LEVELS = '/levels';
export const TITLES = '/titles';
export const PLAN = '/plan';
export const PLAN_ID = '/plan/:id';
export const PROGRESSION = '/progression/:id';
export const ASSESSMENT = '/assessment/:id';
export const ASSESSMENTS = '/assessments';
export const ASSESSMENT_EDITOR = '/assessments/:id';
export const COMPARE = '/compare';

export const INDEX_PATH = PRIVATE + INDEX;
export const ACCOUNT_COMPANY_PATH = PRIVATE + ACCOUNT_COMPANY;
export const ACCOUNT_USERS_PATH = PRIVATE + ACCOUNT_USERS;
export const ACCOUNT_MANAGERS_PATH = PRIVATE + MANAGERS;
export const TEAM_PATH = PRIVATE + TEAM;
export const MEETING_PATH = PRIVATE + MEETING;
export const PROFILE_PATH = PRIVATE + PROFILE;
export const USER_PROFILE_PATH = PRIVATE + '/user-profile';
export const LOGIN_PATH = PUBLIC + LOGIN;
export const FRAMEWORK_PATH = PRIVATE + FRAMEWORK;
export const TITLES_PATH = PRIVATE + TITLES;
export const PLAN_PATH = PRIVATE + PLAN;
export const PROGRESSION_PATH = PRIVATE + PROGRESSION;
export const DASHBOARD_PATH = PRIVATE + DASHBOARD;
export const TEAMS_PATH = PRIVATE + TEAMS;
export const ASSESSMENT_PATH = PRIVATE + ASSESSMENT;
export const ASSESSMENTS_PATH = PRIVATE + ASSESSMENTS;
export const ASSESSMENT_EDITOR_PATH = PRIVATE + ASSESSMENT_EDITOR;
export const COMPARE_PATH = PRIVATE + COMPARE;
