import React, { useState, useEffect } from "react";
import { Container, Row, Col, UncontrolledAlert } from "reactstrap";

import Header from "components/Headers/Header.js";
import Notification from "components/Notification";
import Message from "components/Message";

import FrameworkTabs from "./FrameworkTabs";
import TrackSelector from "./TrackSelector";
import TrackEditor from "./TrackEditor";

import { useAppContext } from "../../contexts/AppContext";
import useFramework from "../../hooks/useFramework";

function Framework() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [track, setTrack] = useState();
  const [selectedMilestone, setSelectedMilestone] = useState(0);
  const [editing, setEditing] = useState(false);
  const { user, account, lang, loading: contextLoading } = useAppContext();

  const {
    framework,
    tracks,
    loading,
    success,
    error,
    setFramework,
    saveFramework,
  } = useFramework(account?.id, user?.id, lang);

  useEffect(() => {
    if (tracks) {
      if (selectedIndex === -1) {
        setTrack(null);
      }
      setTrack({ ...tracks[selectedIndex] });
    }
  }, [tracks, selectedIndex, lang]);

  if (!user || !framework) {
    return null;
  }

  function updateMilestoneContent(content, delta, source) {
    if (source === "user") {
      const updatedFramework = { ...framework };

      const trackToUpdate = updatedFramework.streams
        .find((s) => s.id === track.streamId)
        .tracks.find((t) => t.id === track.id);

      trackToUpdate.milestones[selectedMilestone].content = content;

      setFramework(updatedFramework);
    }
  }

  if (!contextLoading && !account) {
    return (
      <>
        <Header />
        <Notification
          title="You don't have an account"
          message="Please ask your organization admin to send you an invitation"
        />
      </>
    );
  }

  return (
    <>
      <style>{`
          .streams {
            border-spacing: 3px;
          }
          .stream {
            color: black;
            font-size: 12px;
            text-align: center;
            font-weight: normal;
            cursor:pointer
          }
        `}</style>
      <Header />
      <Message
        success={success && "Changes have been saved!"}
        error={error && "Oops. Something went wrong!"}
      />
      <Container className="mt--1" fluid>
        <Row className="align-items-center mb-3">
          <FrameworkTabs />
        </Row>
        <Row>
          <Col>
            <Row className="streams">
              <TrackSelector
                streams={framework.streams}
                track={track}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
              />
            </Row>
            {success && (
              <div className="notif">
                <UncontrolledAlert color="success">
                  <span className="alert-icon">
                    <i className="ni ni-check-bold" />
                  </span>
                  <span className="alert-text ml-1">
                    Changes have been saved!
                  </span>
                </UncontrolledAlert>
              </div>
            )}
            {error && (
              <div className="text-center ml-9 mr-9">
                <UncontrolledAlert color="danger">
                  <span className="alert-text ml-1">{error.message}</span>
                </UncontrolledAlert>
              </div>
            )}
            {track && (
              <TrackEditor
                track={track.track}
                defaultMilestone={selectedMilestone}
                readOnly={user?.role !== "admin"}
                saving={loading}
                onLevelChange={(level) => {
                  setSelectedMilestone(level);
                }}
                onContentChange={updateMilestoneContent}
                onEditToggle={setEditing}
                onSave={saveFramework}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Framework;
