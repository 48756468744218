import * as PATHS from 'constants/paths';

import Profile from 'views/Profile/Profile';
import UserProfile from 'views/Profile/UserProfile';
import Login from 'views/Login.js';
import Users from 'views/Account/Users';
import Managers from 'views/Account/Managers';
import Teams from 'views/Teams/Teams';
import Company from 'views/Account/Company';
import Framework from 'views/Framework/Framework';
import Titles from 'views/Titles/Titles';
import Plan from 'views/Plan/MyPlan';
import HomePage from 'views/Home/HomePage';

import Compare from 'views/Compare/Compare';

import AssessmentsPage from 'views/Assessments/AssessmentsPage';
import AssessmentEditorPage from 'views/Assessments/AssessmentEditor/AssessmentEditorPage';

const routes = [
  {
    path: PATHS.INDEX,
    name: 'Home',
    icon: 'ni ni-tv-2 text-primary',
    component: HomePage,
    layout: PATHS.PRIVATE
  },  
  {
    path: PATHS.ASSESSMENT_EDITOR,
    name: 'Assessment Editor',
    icon: 'ni ni-tv-2 text-primary',
    component: AssessmentEditorPage,
    layout: PATHS.PRIVATE
  },
  {
    path: PATHS.ASSESSMENTS,
    name: 'Assessments',
    icon: 'ni ni-tv-2 text-primary',
    component: AssessmentsPage,
    layout: PATHS.PRIVATE
  },
  {
    path: PATHS.PROFILE,
    name: 'User Profile',
    icon: 'ni ni-single-02 text-yellow',
    component: Profile,
    layout: PATHS.PRIVATE
  },
  {
    path: PATHS.USER_PROFILE,
    name: 'User Profile',
    icon: 'ni ni-single-02 text-yellow',
    component: UserProfile,
    layout: PATHS.PRIVATE
  },
  {
    path: PATHS.ACCOUNT_COMPANY,
    name: 'Company',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Company,
    layout: PATHS.PRIVATE
  },
  {
    path: PATHS.ACCOUNT_USERS,
    name: 'Users',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Users,
    layout: PATHS.PRIVATE
  },
  {
    path: PATHS.MANAGERS,
    name: 'Managers',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Managers,
    layout: PATHS.PRIVATE
  },
  {
    path: PATHS.FRAMEWORK,
    name: 'Framework',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Framework,
    layout: PATHS.PRIVATE
  },
  {
    path: PATHS.TITLES,
    name: 'Titles',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Titles,
    layout: PATHS.PRIVATE
  },
  {
    path: PATHS.PLAN_ID,
    name: 'My Plan',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Plan,
    layout: PATHS.PRIVATE
  },
  {
    path: PATHS.PLAN,
    name: 'My Plan',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Plan,
    layout: PATHS.PRIVATE
  },
  {
    path: PATHS.TEAMS,
    name: 'Teams',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Teams,
    layout: PATHS.PRIVATE
  },
  {
    path: PATHS.LOGIN,
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: PATHS.PUBLIC
  },
  {
    path: PATHS.COMPARE,
    name: 'Progression',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Compare,
    layout: PATHS.PRIVATE
  }
];
export default routes;
