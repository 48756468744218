import { useState, useEffect } from "react";
import { supabase } from "supabase/supabaseClient";
import { USERS } from "constants/databases";

function useMembers(accountId, userId, onlyTeam = false) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    if (accountId) {
      loadUsers();
    }
  }, [accountId, userId, onlyTeam]);

  async function loadUsers() {
    try {
      setLoading(true);

      let query = supabase.from(USERS).select(`*`).eq("account_id", accountId);
      if (onlyTeam) {
        query = query.eq("manager", userId);
      }
      const { data, error, status } = await query;
      if (error && status !== 406) {
        throw error;
      }

      if (data) {
        setUsers(data);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function inviteUsers(adminId, invitations) {
    invitations = invitations.map((invit) => ({
      account_id: accountId,
      status: "pending",
      created_by: adminId,
      updated_by: adminId,
      role: invit.role,
      email: invit.email,
    }));
    try {
      setLoading(true);
      const { error } = await supabase
        .from(USERS)
        .insert(invitations, { returning: "minimal" });
      loadUsers();
      if (error) {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  }

  return { users, inviteUsers, loading, error };
}

export default useMembers;
