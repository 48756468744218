import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  CardHeader,
  ButtonToggle,
} from "reactstrap";

import { useParams } from "react-router-dom";
import Header from "components/Headers/Header.js";
import Notification from "components/Notification";
import Message from "components/Message";

import TrackSelector from "../Framework/TrackSelector";

import PlanBacklog from "./PlanBacklog";
import useFramework from "../../hooks/useFramework";
import usePlan from "../../hooks/usePlan";

import GoalEditor from "./GoalEditor";

import { useAppContext } from "contexts/AppContext";

function MyPlan() {
  const { id: targetUserId } = useParams();
  const { user, account, lang, loading: contextLoading } = useAppContext();
  const readOnly = false;//!!targetUserId;
  const userId = targetUserId || user?.id;
  
  const { framework, tracks } = useFramework(account?.id, user?.id, lang);
  const {
    plan,
    statusList,
    saving,
    success,
    error,
    setPlan,
    addAction,
    updateAction,
    deleteAction,
    savePlan,
  } = usePlan(account?.id, userId, user);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [track, setTrack] = useState();
  const [goalEditing, setGoalEditing] = useState(false);

  function updatePlanGoal(updatedGoal) {
    if (plan && updatedGoal) {
      const updatedPlan = { ...plan, goal: updatedGoal };
      setPlan(updatedPlan);
    }
  }

  useEffect(() => {
    if (tracks) {
      if (selectedIndex === -1) {
        setTrack(null);
      }
      setTrack(tracks[selectedIndex]);
    }
  }, [tracks, selectedIndex, lang]);

  if (!user || !framework) {
    return null;
  }

  if (!contextLoading && !account) {
    return (
      <>
        <Header />
        <Notification
          title="You don't have an account"
          message="Please ask your organization admin to send you an invitation"
        />
      </>
    );
  }

  return (
    <>
      <Header />
      <Message
        success={success && "Changes have been saved!"}
        error={error && "Oops. Something went wrong!"}
      />
      <Container className="mt--1" fluid>
        <Row>
          <Col>
            <Card className="mb-3">
              <CardBody>
                <CardTitle className="mb-0">
                  <Row>
                    <Col>
                      {" "}
                      <h3>Goals</h3>
                    </Col>

                    <Col className="text-right" xs="4">
                      {!readOnly && (
                        <ButtonToggle
                          color="default"
                          href="#edit"
                          outline
                          onClick={(e) => setGoalEditing(!goalEditing)}
                          size="sm"
                        >
                          {goalEditing ? "Close Edit" : "Edit"}
                        </ButtonToggle>
                      )}
                      {!readOnly && goalEditing && (
                        <Button
                          color="primary"
                          disabled={contextLoading}
                          size="sm"
                          onClick={(e) => savePlan()}
                        >
                          Save
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardTitle>
                <GoalEditor
                  readOnly={readOnly || !goalEditing}
                  plan={plan}
                  updatePlanGoal={updatePlanGoal}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Plan</h3>
                  </div>
                  <div className="col text-right">
                    {!readOnly && (
                      <Button
                        color="primary"
                        href="#save"
                        disabled={saving}
                        onClick={(e) => {
                          savePlan();
                        }}
                        size="m"
                      >
                        Save
                      </Button>
                    )}
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="streams">
                  <TrackSelector
                    streams={framework.streams}
                    track={track}
                    displayTracksOnly={true}
                    enableToggle={true}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                  />
                </Row>
                <PlanBacklog
                  filter={track?.track?.displayName}
                  tracks={tracks}
                  plan={plan}
                  statusList={statusList}
                  readOnly={readOnly}
                  setPlan={setPlan}
                  addAction={addAction}
                  updateAction={updateAction}
                  deleteAction={deleteAction}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MyPlan;
