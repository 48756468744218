import React from 'react';

// reactstrap components
import { Button, Container, Row, Col } from 'reactstrap';

class UserHeader extends React.Component {
  render () {
    return (
      <>
        <div
          className="header d-flex align-items-center"
          style={{
            minHeight: '300px',
            // backgroundImage:
            //   "url(" + require("assets/img/theme/profile-cover.jpg") + ")",
            backgroundSize: 'cover',
            backgroundPosition: 'center top'
          }}
        >
          {/* Mask */}
          <span className="mask bg-gradient-default opacity-8" />
          {/* Header container */}
         
        </div>
      </>
    );
  }
}

export default UserHeader;
