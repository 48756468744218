import { useState, useEffect } from "react";
import { supabase } from "supabase/supabaseClient";
import { TEAMS } from "constants/databases";





function useTeams(accountId, userId) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [teams, setTeams] = useState(null);

  useEffect(() => {
    if (accountId) {
      loadTeams();
    }
  }, [accountId, userId]);

  const usersToTreeData = (list = []) => {
    let map = {},
      node,
      res = [],
      i;
    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i;
      list[i].children = [];
    }
    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.project) {
        list[map[node.project]].children.push(node);
      } else {
        res.push(node);
      }
    }
    return res;
  };

  function groupArrayOfObjects(list, key) {
    return list.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  async function loadTeams() {
    try {
      setLoading(true);

      let query = supabase.from(TEAMS).select(`*`).eq("account_id", accountId);
    
      const { data, error, status } = await query;

      if (error && status !== 406) {
        throw error;
      }

      if (data) {


        const graphData = data
          ?.filter((u) => u.user_code)
          .map((user) => ({
            id: user.id,
            //title: user.user_name?.split(" ").map((n)=>n[0]).join("."),
            title: user.type?user.role:user.user_name?.split(" ").map((n)=>n[0])[0]+ ". " + user.user_name?.split(" ").pop(),
            code: user.user_code,
            type: user.type,
            value:1,
            value:1,
            client: user.client || 'NONE',
            project: user.project || user.client || 'NONE',
          }));


        let dataTree = groupArrayOfObjects(graphData, 'project');
          dataTree = Object.keys(dataTree).map((key) => ({
            _id: key,
            title: key,
            client: dataTree[key][0].client,
            children: dataTree[key],
          }));


        let projectTree = groupArrayOfObjects(dataTree, 'client');
        projectTree = Object.keys(projectTree).map((key) => ({
          _id: key,
          title: key,
          children: projectTree[key],
        }));

        let finalTree = projectTree.map((client) => {
          if(client.children.length === 1) {
            return {
              _id: client._id,
              title: client.title,
              children: client.children[0].children,
            
            };

          } else {
            return {
              _id: client._id,
              title: client.title,
              children: client.children,
            };
          
          }

        });

        setTeams({ children: finalTree });

      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  return { teams, loading, error };
}

export default useTeams;
