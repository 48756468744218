import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { Col, Row, Button } from "reactstrap";
import Action from "./Action";

const BoardColumn = ({
  status,
  elements,
  tracks,
  addItem,
  updateItem,
  deleteItem,
  activeFilter,
  readOnly,
}) => (
  <div style={{ padding: "10px", borderRadius: "3px", background: "#f1f3f8" }}>
    <Row>
      <Col>
        <h3 style={{ marginBottom: "20px" }}>{status.display}</h3>
      </Col>
      <Col className="col-auto">
        {!readOnly && (
          <Button
            color="default"
            href="#add"
            outline
            className="border-0"
            onClick={() =>
              addItem({
                status: status.id,
                editing: true,
                tags: [],
                ...(activeFilter && { tags: [activeFilter] }),
              })
            }
            size="sm"
          >
            <i className="fas fa-plus" />
          </Button>
        )}
      </Col>
    </Row>
    <Row>
      <Col>
        <Droppable droppableId={`${status.id}`}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {elements.map((item, index) => (
                <Action
                  key={item.id}
                  item={{ ...item, status: status.id }}
                  index={index}
                  tracks={tracks}
                  updateItem={updateItem}
                  deleteItem={deleteItem}
                  readOnly={readOnly}
                />
              ))}
              {provided.placeholder}
              {!readOnly && (
                <Button
                  color="default"
                  href="#add"
                  outline
                  className="btn-icon-clipboard p-2"
                  onClick={() =>
                    addItem({
                      status: status.id,
                      editing: true,
                      tags: [],
                      ...(activeFilter && { tags: [activeFilter] }),
                    })
                  }
                  size="sm"
                >
                  <i className="fas fa-plus" style={{ fontSize: "0.9rem" }} />{" "}
                  <span>Add an action</span>
                </Button>
              )}
            </div>
          )}
        </Droppable>
      </Col>
    </Row>
  </div>
);

export default BoardColumn;
