import React from "react";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import ZoomableCirclePack from "components/Charts/ZoomableCirclePack";

import { formattedData } from "./data.js";

import useTeams from "hooks/useTeams";


function ProjectsView({accountId, userId}) {

  const { teams, loading } = useTeams(accountId, userId);

  return (
    <ParentSize>
        {({ width, height }) => (
          <ZoomableCirclePack data={teams} width={width} height={height} />
        )}
      </ParentSize>
  );
}

export default ProjectsView;
