import { useState, useEffect } from "react";
import { supabase } from "supabase/supabaseClient";
import { ASSESSMENTS } from "constants/databases";

function useMyLevel(accountId, userId) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [assessment, setAssessment] = useState(null);

  function convertAssessment(assessment){
    if(assessment?.progression?.tracks) {
      const skills = assessment.progression.tracks.map((track) => ({
        skillId: track.id,
        skillName: track.displayName,
        categoryId: track.streamId,
        categoryName: track.streamName,
        color: track.color,
        level: track.score,
        evidence: track.evidence
      }));

      return {
        ...assessment,
        progression: skills
      }; 
    } else{
      return assessment;
    };
  }

  useEffect(() => {
    if (accountId && userId) {
      loadMyLastAssessment();
    }
  }, [accountId, userId]);

  async function loadMyLastAssessment() {
    try {
      setLoading(true);

      const { data, error, status } = await supabase
        .from(ASSESSMENTS)
        .select(
          `*`
        )
        .eq("user_id", userId)
        .eq("account_id", accountId)
        .order('updated_at', { ascending: false })
        .limit(1)
        .single();
  
      if (error && status !== 406) {
        throw error;
      }

      if (data) {
        const assess = convertAssessment(data);
        setAssessment(assess);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  return { assessment, loading, error };
}

export default useMyLevel;
