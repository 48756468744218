import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { UserAvatar } from '../../components/Avatars/UserAvatar';

function BasicProfile({ profile }) {
  if (!profile) {
    return null;
  }

  return (
    <Card className="card-profile shadow">
      <Row className="justify-content-center">
        <Col className="order-lg-2" lg="3">
          <div className="card-profile-image">
            {profile.avatar ? (
              <img  className="rounded-circle" src={profile.avatar} />
            ) : (
              <UserAvatar displayName={profile.name} className='avatar-xl bg-primary' />
            )}
          </div>
        </Col>
      </Row>
      <CardBody className="pt-0 pt-md-4">
        <Row>
          <div className="col">
            <div className="card-profile-stats d-flex justify-content-center mt-md-5">
              <div className="text-center">
                <h3>
                  {profile.name}, {profile.code}
                  <span className="font-weight-light"></span>
                </h3>
                <div className="h5 font-weight-300">
                  {profile.email}, {profile.phone}
                </div>
                <div className="h5 mt-4"> {profile.positionName}</div>
                <div>Manager: {profile.managerProfile?.name}</div>
                <hr className="my-4" />
                <p>{profile.bio}</p>
              </div>
            </div>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
}

export default BasicProfile;
