import React from 'react';
import {
  Row,
  Col
} from 'reactstrap';

import ReactQuill from 'react-quill';


function GoalEditor ({ plan, readOnly, updatePlanGoal }) {
  if (!plan) {
    return null;
  }

  const toolbarOptions = !readOnly
  ? [
      [{ header: [2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'color', 'background'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image']
    ]
  : false;

  return (
    <ReactQuill theme="snow"
        style={{fontWeight: 300}}
        readOnly={readOnly}
        modules={{ toolbar: toolbarOptions }}
        value={plan?.goal}
        onChange={updatePlanGoal}
      />
  );
}

export default GoalEditor;
