import React from 'react';
import { useForm } from 'react-hook-form';

import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button
} from 'reactstrap';

import {FormInput} from 'components/FormInput';

function CompanyProfile ({ company, userId, createAccount, updateAccount }) {

  const { register, handleSubmit } = useForm();
  
  const onSubmit = data => {
    if (company && company.id) {
      updateAccount(company.id, data.name, data.headline, userId);
    } else {
      createAccount(data.name, data.headline, userId);
    }
  };

  return (
      <Card className="shadow">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Company Profile</h3>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                  >
                    Company Name
                  </label>
                  <FormInput defaultValue={company ? company.name : ''} {...register("name", { required: true })} />
                </FormGroup>
                </Col>
                <Col md="6">

                </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                  >
                    Company Headline
                  </label>
                  <FormInput defaultValue={company ? company.headline : ''} type="textarea"  {...register("headline")} />
                </FormGroup>
                </Col>
                <Col md="6">

                </Col>
            </Row>
              {company ? <Button type="submit" color="secondary">Update</Button> : <Button type="submit" color="primary">Create</Button> }
            </Form>
          </CardBody>
      </Card>
  );
}

export default CompanyProfile;
