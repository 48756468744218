import React from 'react';

// reactstrap components
import {
  Container
} from 'reactstrap';

class Header extends React.Component {
  render () {
    return (
      <>
        <div className="header pb-3 pt-7">
          <Container fluid>
            <div className="header-body">
              {this.props.children}
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
