import React, { useState } from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import { Tooltip } from "@visx/tooltip";

import { UserAvatar } from "components/Avatars/UserAvatar";

const UserTooltip = ({ tooltipData, tooltipLeft, tooltipTop }) => {
  if (!tooltipData) return null;

  return (
    <Tooltip left={tooltipLeft} top={tooltipTop}>
      <Card className="border-0 mb-0">
        <CardBody>
          <Row className="align-items-center">
            <Col className="col-auto">
              <UserAvatar
                className="avatar avatar-xl rounded-circle"
                initials={tooltipData.code}
                displayName={tooltipData.name}
                photoUrl={tooltipData.avatar}
              />
            </Col>
            <div className="col ml--2">
              <h4 className="mb-0">
                <a href="#user" onClick={(e) => e.preventDefault()}>
                  {tooltipData.name}
                </a>
              </h4>
              <p className="text-sm text-muted mb-0">{tooltipData.email}</p>
            </div>
            <Col className="col-auto"></Col>
          </Row>
        </CardBody>
      </Card>
    </Tooltip>
  );
};

export default UserTooltip;
