import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useUser } from 'use-supabase';

import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import { PRIVATE, INDEX_PATH } from 'constants/paths';
import routes from 'routes.js';

function Private (props) {
  const user = useUser();
  if (!user) {
    return (
      <Redirect from="*" to={'/public/login?redirect=' + props.location.pathname }/>
    );
  }

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === PRIVATE) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
      <>
        <div className="main-content" refs="mainContent">
          <AdminNavbar
            {...props}
            brandText={'My Path'}
          />
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to={INDEX_PATH} />
          </Switch>
        </div>
      </>
  );
}

export default Private;
