import React from 'react';
import ReactDOM from 'react-dom';

import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/teemz-app.scss';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
