import { useState } from 'react';
import { supabase } from '../supabase/supabaseClient';

import { ACCOUNTS } from 'constants/databases';

function useAccount () {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  async function createAccount (name, headline, createdBy) {
    try {
      setLoading(true);
      const account = {
        name,
        headline,
        created_at: new Date(),
        created_by: createdBy
      };
      const { error } = await supabase.from(ACCOUNTS).insert(account);
      if (error) {
        throw error;
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function updateAccount (id, name, headline, updatedBy) {
    try {
      setLoading(true);
      const updates = {
        name,
        headline,
        updated_at: new Date(),
        updated_by: updatedBy
      };

      const { error } = await supabase.from(ACCOUNTS).update(updates, {
        returning: 'minimal' // Don't return the value after inserting
      })
      .eq('id', id);
      if (error) {
        throw error;
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  }

  return { createAccount, updateAccount, loading, error };
}

export default useAccount;
