import React from "react";

import {
  Card,
  Button,
  CardHeader,
  Col,
  CardBody,
  Row,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";
import { Link } from "react-router-dom";

import StatusBadge from "components/StatusBage";
import NightingaleChart from "components/Charts/NightingaleChart";
import LevelRadarChart from "components/Charts/LevelRadarChart";

import { getTitleById } from "constants/data";

export default function AssessmentOverview({
  user,
  assessment,
  loading,
  error,
  actionVisible = true,
}) {
  const title = getTitleById(user?.position);

  if (loading) {
    return (
      <Card className="shadow">
        <div className="d-flex justify-content-center p-9">
          <Spinner> </Spinner>
        </div>
      </Card>
    );
  }
  if (error) {
    return (
      <Card className="shadow">
        <div className="text-center justify-content-center ml-9 mr-9">
          <UncontrolledAlert color="danger" closeClassName="notif-close-btn">
            <span className="alert-text ml-1">{error}</span>
          </UncontrolledAlert>
        </div>
      </Card>
    );
  }
  return (
    <Card className="bg- border-0">
      <CardHeader className="bg-transparent">
        <Row>
          <Col xs="8">
            <Row>
              <Col className="d-flex">
                <div className="d-flex">
                  <h3 className="mr-2">Assessment</h3>
                  <StatusBadge status={assessment?.status} />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs="4" className="text-right">
            {assessment && (
              <Button
                color="secondary"
                to={"/app/assessments/" + assessment.id}
                tag={Link}
                size="sm"
              >
                View assessment
              </Button>
            )}
            <Button
              color="secondary"
              to={"/app/plan/" + user?.id}
              tag={Link}
              size="sm"
            >
              View plan
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          {assessment ? (
            <>
              <Col className="mb-5 mb-xl-0" xl="6">
                <div
                  className="border-0 card text-sm text-center p-3 mb-0"
                  style={{ background: "#a5ccf426", minHeight: "450px" }}
                >
                  {" "}
                  <LevelRadarChart
                    typicalProfile={title}
                    skillsAssessment={assessment?.progression}
                  />
                </div>
              </Col>
              <Col xl="6">
                <Card
                  className="border-0 text-sm text-center p-3 mb-0"
                  style={{ background: "#a5ccf426", minHeight: "450px" }}
                >
                  <NightingaleChart skills={assessment?.progression} />
                </Card>
              </Col>
            </>
          ) : (
            <Col className="text-center">
              <div className="p-5">
                <i className="fa-solid fa-ban fa-2xl" />
                {actionVisible && (
                  <>
                    <p>Get started by submiting your first assessment</p>
                    <Button
                      color="primary"
                      href="#save"
                      to={"/app/assessments/new"}
                      tag={Link}
                      size="m"
                    >
                      Start new assessment
                    </Button>
                  </>
                )}
              </div>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
}
