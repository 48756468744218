import { useState, useEffect } from "react";
import { supabase } from "supabase/supabaseClient";
import { USERS } from "constants/databases";

function useTeamDashboard(accountId, userId, onlyTeam, includeCurrentUser) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    if (accountId) {
      loadUsers();
    }
  }, [accountId, userId, onlyTeam]);

  async function loadUsers() {
    try {
      setLoading(true);
      let query = supabase
        .from(USERS)
        .select(
          `
            id, name, email, avatar, code,
            assessments!assessments_user_id_fkey (id, status, updated_at),
            plans!plans_user_id_fkey (id, goal, actions)
        `
        )
        .eq("account_id", accountId)
        .eq("status", "active");

      if (onlyTeam) {
        if (includeCurrentUser) {
          query = query.or(`manager.eq.${userId},id.eq.${userId}`);
        } else {
          query = query.eq("manager", userId);
        }
      }
      query = query.order("updated_at", {
        foreignTable: "assessments",
        ascending: false,
        nullsFirst: false,
      });

      const { data, error, status } = await query;
      if (error && status !== 406) {
        throw error;
      }

      if (data) {
        setUsers(data);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  return { users, loading, error };
}

export default useTeamDashboard;
