import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSupabase, useUser } from 'use-supabase';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from 'reactstrap';

function Login (props) {
  const query = new URLSearchParams(props.location.search);
  const redirect = query.get('redirect');

  const [submiting, setSubmiting] = useState(false);
  const [email, setEmail] = useState('');
  const [passwd, setPasswd] = useState('');

  const { auth } = useSupabase();
  const user = useUser();

  async function signInWithEmail (email, password) {
    try {
      setSubmiting(true);
      const { error } = await auth.signIn({
        email: email,
        password: password
      });
      if (error) throw error;
    } catch (error) {
      alert(error.error_description || error.message);
    } finally {
      setSubmiting(false);
    }
  };

  async function signInWithGoogle () {
    try {
      setSubmiting(true);
      const { error } = await auth.signIn({
        provider: 'google'
      });
      if (error) throw error;
    } catch (error) {
      alert(error.error_description || error.message);
    } finally {
      setSubmiting(false);
    }
  }

  if (user) {
    return <Redirect to={redirect} />;
  }

  return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div className="btn-wrapper text-center">
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#login-google"
                  onClick={(e) => {
                    e.preventDefault();
                    signInWithGoogle();
                  }}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require('assets/img/icons/common/google.png')}
                    />
                  </span>
                  <span className="btn-inner--text">Log in with Google</span>
                </Button>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-3">
              <div className="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    <Input placeholder="Email" type="email" autoComplete="new-email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    <Input placeholder="Password" type="password" autoComplete="new-password"
                      value={passwd}
                      onChange={(e) => setPasswd(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button disabled={submiting} className="my-4" color="primary" type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      signInWithEmail(email, passwd);
                    }}
                  >
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#password"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#account"
                onClick={e => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
  );
}

export default Login;
