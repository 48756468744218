import React from 'react';
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import { FRAMEWORK_PATH, TITLES_PATH } from "constants/paths";

function FrameworkTabs () {
  return (
    <div className="col d-flex">
    <Button
      outline
      color="primary"
      className="border-0"
      to={FRAMEWORK_PATH}
      tag={Link}
    >
      Growth Framework
    </Button>
    <div
      className="ml-2 mr-2"
      style={{ width: "1px", backgroundColor: "#9ea9ef" }}
    ></div>
    <Button
      outline
      color="secondary"
      className="border-0"
      to={TITLES_PATH}
      tag={Link}
    >
      Titles
    </Button>
  </div>

  );
}

export default FrameworkTabs;
