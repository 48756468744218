import React, { useState } from "react";
import {
  Button,
  ButtonToggle,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import RichEditor from "components/RichEditor";

function TrackEditor({
  track,
  defaultMilestone,
  readOnly,
  saving,
  onLevelChange,
  onContentChange,
  onEditToggle = () => {},
  onSave
}) {
  const [editing, setEditing] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(
    defaultMilestone || 0
  );

  if (!track) {
    return <Card className="border-0" style={{ minHeight: "700px" }}>
      No track selected
      </Card>;
  }

  function changeLevel(level) {
    setSelectedLevel(level);
    onLevelChange(level);
  }

  function toggleEdit() {
    onEditToggle(!editing);
    setEditing(!editing);
  }

  return (
    <div className="track">
      <style>{`
          .track-table {
            border-collapse: separate;
            border-spacing: 3px;
          }
          .track-table  td {
            color:black;
            line-height: 50px;
            width: 50px;
            text-align: center;
            background: #eee;
            font-weight: bold;
            font-size: 24px;
            border-radius: 3px;
            cursor: pointer;
          }
          ul {
            line-height: 1.5em;
          }
        `}</style>
      <Card className="border-0" style={{ minHeight: "700px" }}>
        <CardHeader>
          <Row>
            <Col xs="8">
              <h3 className="h3 title">
                <span className="d-block mb-1">{track.displayName}</span>
                <small className="h4 font-weight-light text-muted">
                  {track.description}
                </small>
              </h3>
            </Col>
            <Col className="text-right" xs="4">
              {!readOnly && (
                <ButtonToggle
                  color="default"
                  href="#edit"
                  outline
                  onClick={(e) => toggleEdit()}
                  size="m"
                >
                  {editing ? "Close Edit" : "Edit"}
                </ButtonToggle>
              )}
              {!readOnly && editing && (
                <Button
                  color="primary"
                  disabled={saving}
                  size="m"
                  onClick={(e) => onSave()}
                >
                  Save
                </Button>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div style={{ display: "flex" }}>
            <table
              className="track-table"
              style={{ flex: 0, marginRight: 30, maxHeight: "250px" }}
            >
              <tbody>
                {track.milestones.map((milestone, index) => {
                  return (
                    <tr key={index}>
                      <td
                        onClick={() => changeLevel(index)}
                        style={{
                          border: `4px solid ${
                            index === selectedLevel ? "#000" : "#eee"
                          }`,
                          background: undefined,
                        }}
                      >
                        {index + 1}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <RichEditor
              readOnly={!editing}
              content={track.milestones[selectedLevel]?.content}
              onChange={onContentChange}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default TrackEditor;
