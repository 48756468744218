import React from "react";
import {
  Badge,
} from "reactstrap";

const GoalStatus = ({ goal }) => {
  let badgeColor = "";
  let badgeText = "";

  if (!goal || goal.length === 0) {
    badgeColor = "bg-danger";
    badgeText = "No Goals";
  } else if (goal.length < 500) {
    badgeColor = "bg-warning";
    badgeText = "Needs work";
  } else {
    badgeColor = "bg-success";
    badgeText = "Defined";
  }

  return (
    <Badge color="" className="badge-dot mr-4">
      <i className={badgeColor} />
      {badgeText}
    </Badge>
  );
};

export default GoalStatus;