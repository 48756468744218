import React from "react";
import { Button, Col, Card, CardTitle, CardBody, Row } from "reactstrap";

import RichEditor from "components/RichEditor";

function EvidenceEditor({ content, readOnly, expanded, onChange, onExpand }) {
  function updateContent(content, delta, source) {
    if (source === "user") {
      onChange(content);
    }
  }

  return (
    <Card
      className="border-0 card p-3"
      style={expanded? { background: "#f6f9fc", minHeight: "900px" } : { background: "#f6f9fc", minHeight: "585px" }}
    >
      <CardTitle tag="h3">
        <Row>
          <Col xs="10">
            <span className="d-block mt-3 mb-1">
              Why I believe I&apos;ve reached this level
            </span>
          </Col>
          <Col xs="2" className="text-right">
            <Button
              color="default"
              outline
              onClick={onExpand}
              size="sm"

            >
              <i className={expanded ? "fa fa-down-left-and-up-right-to-center text-muted" : "fa fa-up-right-and-down-left-from-center text-muted "} />
            </Button>
          </Col>
        </Row>
        <small className="h4 font-weight-light text-muted">
          Explain why you think you&apos;ve reached this level based on evidence
          and feedback from peers and clients.
        </small>
      </CardTitle>
      <CardBody className="p-0 border bg-white">
        <RichEditor
          compact={true}
          readOnly={readOnly}
          content={content}
          onChange={updateContent}
        />
      </CardBody>
    </Card>
  );
}

export default EvidenceEditor;
