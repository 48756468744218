import React from "react";
import ParentSize from "@visx/responsive/lib/components/ParentSize";

import ManagersTree from "./ManagersTree";
import useMembers from "hooks/useMembers";

function ManagersView({accountId, userId}) {

  const { users, loading } = useMembers(accountId);
  
  if(loading || !users) {
    return null;
  }

  const usersToTreeData = (list = []) => {
    let map = {},
      node,
      res = [],
      i;
    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i;
      list[i].children = [];
    }
    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.manager) {
        list[map[node.manager]]?.children.push(node);
      } else {
        res.push(node);
      }
    }
    return res;
  };

  const graphData = users
    ?.filter((u) => u.code)
    .map((user) => ({
      id: user.id,
      name: user.code,
      displayName: user.name,
      manager: user.manager,
    }));

  let data = usersToTreeData(graphData)[0];

  return (
    <ParentSize>
      {({ width, height }) => (
        <ManagersTree
          width={width}
          height={height}
          data={data}
          users={users}
          currentUser={userId}
        />
      )}
    </ParentSize>
  );
}

export default ManagersView;
