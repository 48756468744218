import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";

import Header from "components/Headers/Header.js";
import Notification from "components/Notification";
import SearchBox from "components/SearchBox";

import { useAppContext } from "contexts/AppContext";

import AssessmentRadarCard from "./AssessmentRadarCard";

function Compare() {
  const { account, loading: contextLoading } = useAppContext();
  const [persons, setPersons] = useState([]);

  function addPerson(e) {
    const existting = persons.find((p) => p.id === e.value);
    if (!existting) {
      const person = { id: e.value, name: e.label };
      setPersons([...persons, person]);
    }
  }

  function removePerson(person) {
    const filtred = persons.filter((p) => p.id !== person.id);
    setPersons(filtred);
  }

  if (!contextLoading && !account) {
    return (
      <>
        <Header />
        <Notification
          title="You don't have an account"
          message="Please ask your organization admin to send you an invitation"
        />
      </>
    );
  }

  return (
    <>
      <Header />
      <Container className="mt--1" fluid>
        <SearchBox account={account} onSelect={addPerson} />
        <Row>
          {persons?.map(function (person) {
            return (
              <Col key={person.id} className="mt-3">
                <AssessmentRadarCard
                  user={{ accountId: account.id, ...person }}
                  onClose={removePerson}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
}

export default Compare;
