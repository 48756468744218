import React from "react";
import { Badge } from "reactstrap";
function getStatusLabel(status) {
  switch (status) {
    case "draft":
      return { label: "In Progress", color: "bg-warning" };
    case "submitted":
      return { label: "In Review", color: "bg-warning" };
    case "reviewed":
      return { label: "To Approve", color: "bg-warning" };
    case "approved":
      return { label: "Approved", color: "bg-success" };
    case "archived":
      return { label: "Archived", color: "bg-success" };
    default:
      return { label: "Not Started", color: "bg-danger" };
  }
}

export default function StatusBadge({ status, onlyDraft }) {
  const statusLabel = getStatusLabel(status);

  if (onlyDraft && (status === "approved" || status === "archived")) {
    return null;
  }
  return (
    <div>
      <Badge
        pill={false}
        className={
          "badge badge-xs bg-secondary text-white " + statusLabel.color
        }
      >
        {statusLabel.label}
      </Badge>
    </div>
  );
}
