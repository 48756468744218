import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  Media,
  NavbarToggler,
} from "reactstrap";

import * as PATHS from "constants/paths";

import SearchBox from "./SearchBox";

import { useAppContext } from "contexts/AppContext";

function AdminNavbar(props) {
  const { user, authUser, lang, setLang, signOut } = useAppContext();
  const [open, setOpen] = useState(false);

  const switchLang = () => {
    setLang(lang === "Fr" ? "En" : "Fr");
  };

  const toggle = () => {
    setOpen(!open);
  };

  const userName =
    (user && user.name) ||
    (authUser && authUser.user_metadata && authUser.user_metadata.full_name);
  const userPhoto =
    (user && user.avatar) ||
    (authUser && authUser.user_metadata && authUser.user_metadata.avatar_url);

  return (
    <>
      <Navbar id="navbar-main" className="navbar-top" expand="md">
        <NavbarBrand to="/" tag={Link} style={{ color: "#001e57" }}>
          <img
            alt="..."
            style={{ height: "1.9rem" }}
            src={require("assets/img/logo.png")}
          />
          <span
            className="h3 ml-3 mb-0 text-uppercase d-none d-lg-inline-block"
            style={{ color: "#001e57" }}
          >
            {props.brandText}
          </span>
        </NavbarBrand>
        <NavbarToggler onClick={toggle}>
          <i className="fa fa-bars" />
        </NavbarToggler>
        <Collapse isOpen={open} navbar>
          <Nav className="ml-auto mr-5" navbar>
            <NavItem>
              <NavLink
                className="nav-link-icon"
                to={PATHS.FRAMEWORK_PATH}
                tag={Link}
              >
                <span className="nav-link-inner--text mb-0 text-sm font-weight-bold">
                  Framework
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="nav-link-icon"
                to={PATHS.ASSESSMENTS_PATH}
                tag={Link}
              >
                <span className="nav-link-inner--text mb-0 text-sm font-weight-bold">
                  Assessment
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="nav-link-icon"
                to={PATHS.PLAN_PATH}
                tag={Link}
              >
                <span className="nav-link-inner--text mb-0 text-sm font-weight-bold">
                  Plan
                </span>
              </NavLink>
            </NavItem>
          </Nav>
          <SearchBox />
          <Button
            outline
            type="button"
            color="default"
            className="ml-3 btn-icon-only rounded-circle"
            onClick={() => switchLang()}
          >
            {lang}
          </Button>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img alt="..." src={userPhoto} />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {userName}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow">
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Sign In</h6>
                </DropdownItem>
                {user?.role === "admin" && (
                  <>
                    <DropdownItem to={PATHS.ACCOUNT_USERS_PATH} tag={Link}>
                      <i className="ni ni-building" />
                      <span>Account</span>
                    </DropdownItem>
                    <DropdownItem to={PATHS.TEAMS_PATH} tag={Link}>
                      <i className="fa fa-users" />
                      <span>Teams</span>
                    </DropdownItem>
                  </>
                )}
                <DropdownItem to="/app/profile" tag={Link}>
                  <i className="ni ni-badge" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#" onClick={(e) => signOut()}>
                  <i className="ni ni-button-power" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
