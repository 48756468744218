import { useState, useEffect } from "react";
import { supabase } from "supabase/supabaseClient";
import { ASSESSMENTS } from "constants/databases";

function useAssessmentsHistory(accountId, userId) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [assessments, setAssessments] = useState(null);

  useEffect(() => {
    if (accountId && userId) {
      loadMyAssessments();
    }
  }, [accountId, userId]);

  async function loadMyAssessments() {
    try {
      setLoading(true);

      const { data, error, status } = await supabase
        .from(ASSESSMENTS)
        .select(`id, updated_at, status`)
        .eq("user_id", userId)
        .eq("account_id", accountId)
        .order("status", { ascending: true })
        .order("updated_at", { ascending: false });

      if (error && status !== 406) {
        throw error;
      }

      if (data) {
        setAssessments(data);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function deleteAssessment(id) {
    try {
      setLoading(true);

      const { data, error } = await supabase
        .from(ASSESSMENTS)
        .delete()
        .eq("id", id);

      if (error && status !== 406) {
        throw error;
      }
      if (data) {
        loadMyAssessments();
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  return { assessments, deleteAssessment, loading, error };
}

export default useAssessmentsHistory;
