import React from 'react';

import { UncontrolledAlert } from 'reactstrap';

function Message ({ success, error }) {
  return (
    <>
      <style>{`
          .notif{
            position: fixed;
            top: 10px;
            left: 50%;
            transform: translate(-50%);
            z-index:99999999;
          }
          .notif-close-btn{
            display: none;
          }
          .btn-close{
            display: none;
          }
        `}</style>
      {success && (
        <div className="notif">
          <UncontrolledAlert color="success" closeClassName="notif-close-btn">
            <span className="alert-icon">
              <i className="ni ni-check-bold" />
            </span>
            <span className="alert-text ml-1">{success}</span>
          </UncontrolledAlert>
        </div>
      )}
      {error && (
        <div className="text-center ml-9 mr-9">
          <UncontrolledAlert color="danger" closeClassName="notif-close-btn">
            <span className="alert-text ml-1">{error}</span>
          </UncontrolledAlert>
        </div>
      )}
    </>
  );
}

export default Message;
