import React from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle
} from 'reactstrap';

import LevelRadarChart from 'components/Charts/LevelRadarChart';


function TitleDetail ({ title, skills }) {
  if (!title) {
    return null;
  }

  return (
    <>
      <div>
          <h2 className="mb-0">{title.label}</h2>
          <div>
            <p className="text-muted">
              {title.description}
            </p>
            <div style={ { fontWeight: 300, lineHeight: 1.7 }}>
              <ReactMarkdown className="text-sm">{title.vision}</ReactMarkdown>
              </div>
          </div>
      </div>
      <Row>
        <Col className="mb-5 mb-xl-0 pr-5" xl="6">
          <Card className="border-0 card" style={{ background: '#f6f9fc', minHeight: '485px' }}>
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h4"
                      className="text-uppercase mb-3 text-center"
                    >
                        Points de sagesse
                    </CardTitle>
                      <div style={ { fontWeight: 300, lineHeight: 1.7 }}>
                        <ReactMarkdown className="text-sm">{title.wisdom}</ReactMarkdown>
                      </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
        </Col>
        <Col xl="6">
          <Card className=" border-0 card" style={{ background: '#f6f9fc', minHeight: '400px' }}>
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h4"
                      className="text-uppercase mb-3 text-center"
                    >
                      Impact Radar
                    </CardTitle>
                      <div className="mt-3 mb-0 text-sm text-center">
                      <LevelRadarChart
                          typicalProfile={title}
                          skillsAssessment={skills}
                        />
                      </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
        </Col>
      </Row>
      </>
  );
}

export default TitleDetail;
