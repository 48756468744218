import { useState, useEffect } from "react";
import { supabase } from "supabase/supabaseClient";
import { getTitleById } from "constants/data";

function useProfile(userId) {
  const [profile, setProfile] = useState(null);
  const [title, setTitle] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (userId) {
      loadProfile();
    }
  }, [userId]);

  async function loadProfile() {
    try {
      setLoading(true);
      const { data, error, status } = await supabase
        .from("users")
        .select(
          `id, code, email, name, avatar, phone, bio, position, manager, created_at, updated_at`
        )
        .eq("id", userId)
        .single();

      if (error && status !== 406) {
        throw error;
      }

      if (data) {
        const title = getTitleById(data?.position);
        const manager = await loadUser(data?.manager);
        setProfile({ ...data, managerProfile: manager, positionName: title?.label });
        setTitle(title);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function loadUser(id) {
    if (!id) return null;
    const { data, error, status } = await supabase
      .from("users")
      .select(`id, code, email, name`)
      .eq("id", id)
      .single();

    if (error && status !== 406) {
      throw error;
    }
    return data;
  }

  async function updateProfile(data) {
    try {
      setSaving(true);
      setSuccess(false);
      const updates = {
        name: data.name,
        code: data.code,
        phone: data.phone,
        position: data.position,
        manager: data.manager,
        bio: data.bio,
        updated_at: new Date(),
      };
      const { error } = await supabase
        .from("users")
        .update(updates, {
          returning: "minimal", // Don't return the value after inserting
        })
        .eq("id", userId);

      if (error) {
        throw error;
      }
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } catch (error) {
      setError(error);
    } finally {
      setSaving(false);
    }
  }

  return { profile, title, loading, saving, success, error, updateProfile };
}

export default useProfile;
