import React from "react";
import ReactQuill from "react-quill";

function RichEditor({ content, readOnly, compact, onChange }) {
  const fullToolbar = [
    [{ header: [2, 3, 4, 5, 6, false] }],
    [
      "bold",
      "italic",
      "underline",
      "strike",
      {
        color: [
          "#32325d",
          "#fff",
          "#428af6",
          "#5e72e4",
          "#11cdef",
          "#2dce89",
          "#f5365c",
        ],
      },
      {
        background: [
          "#fff",
          "#fef445",
          "#cee741",
          "#d5b1db",
          "#f5365c",
          "#fb6340",
        ],
      },
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { list: 'check' },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
  ];

  const compactToolbar = [
    [{ header: [ 3, 4, 5, false] }],
    [
      "bold",
      "italic",
      "underline",
      "strike"
    ],
    [
      { list: "ordered" },
      { list: "bullet" }
    ]
  ];

  const toolbarOptions = !readOnly
    ? (compact ? compactToolbar : fullToolbar)
    : false;

  return (
    <ReactQuill
      theme="snow"
      readOnly={readOnly}
      modules={{ toolbar: toolbarOptions }}
      value={content}
      onChange={onChange}
    />
  );
}

export default RichEditor;
