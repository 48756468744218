import React, { useState } from "react";
import { ButtonGroup, Button, Container, Row, Col, Card } from "reactstrap";

import Header from "components/Headers/Header.js";
import ProjectsView from "./ProjectsView";
import ManagersView from "./ManagersView/ManagersView";


import { useAppContext } from "../../contexts/AppContext";

function Teams() {
  const [view, setView] = useState("projects");
  const { user, account } = useAppContext();

  if (!account || !user) {
    return null;
  }
  
  return (
    <>
      <Header />
      <Container fluid>
        <Row>
          <Col>
            <div className="mb-3 ml-3">
              <ButtonGroup aria-label="Graphe Type" role="group">
                <Button
                  className={view === "managers" ? "active" : ""}
                  color="secondary"
                  type="button"
                  onClick={(e) => setView("managers")}
                >
                  <span className="btn-inner--icon">By manager</span>
                </Button>
                <Button
                  className={view === "projects" ? "active" : ""}
                  color="secondary"
                  type="button"
                  onClick={(e) => setView("projects")}
                >
                  <span className="btn-inner--icon">By project</span>
                </Button>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
        {view === "projects" && (
          <Row>
            <Col>
              <Card className="shadow" style={{ height: "90em" }}>
                <ProjectsView accountId={account?.id} userId={user?.id}/>
              </Card>
            </Col>
          </Row>
        )}
        {view === "managers" && (
          <Row>
            <Col>
              <Card className="shadow" style={{ height: "60em" }}>
                <ManagersView accountId={account?.id} userId={user?.id} />
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

export default Teams;
