import React from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function ConfirmModal({ isOpen, message, onConfirm = () => {}, onCancel = () => {} }) {
  return (
    <Modal className="text-center" isOpen={isOpen}>
      <ModalBody>
        <h3 className="p-4">{message || "Are you sure?"}</h3>
        <Button color="primary" onClick={onConfirm}>
          Confirm
        </Button>{" "}
        <Button onClick={onCancel}>Cancel</Button>
      </ModalBody>

    </Modal>
  );
}

export default ConfirmModal;
