import { useState, useEffect } from 'react';
import { supabase } from '../supabase/supabaseClient';
import { FRAMEWORKS } from 'constants/databases';

function useFramework (accountId, userId, lang) {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [framework, setFramework] = useState({});
  const [tracks, setTracks] = useState();
  const [error, setError] = useState(null);

  function setFrameworkData (data) {
    if (data) {
      setFramework(data);
      const tracksList = data.streams
        .map((stream) =>
          stream.tracks.map((track) => ({
            id: track.id,
            displayName: track.displayName,
            streamId: stream.id,
            streamName: stream.name,
            color: stream.color,
            track: track
          }))
        )
        .flat();
      setTracks(tracksList);
    }
  }

  useEffect(() => {
    async function loadFramework (accountId, lang) {
      if (!accountId) {
        return;
      }
      try {
        setLoading(true);
        const { data, error, status } = await supabase
          .from(FRAMEWORKS)
          .select('*')
          .eq('account_id', accountId)
          .eq('lang', lang || 'En')
          .order('updated_at', { ascending: false })
          .limit(1)
          .single();
        if (error && status !== 406) {
          throw error;
        }
        if (data) {
          setFrameworkData(data);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    loadFramework(accountId, lang);
  }, [accountId, lang]);

  async function saveFramework () {
    try {
      setSaving(true);
      setSuccess(false);
      const updates = framework.id
        ? {
            ...framework,
            updated_at: new Date(),
            updated_by: userId
          }
        : {
            account_id: accountId,
            streams: framework.streams,
            created_at: new Date(),
            updated_at: new Date(),
            created_by: userId,
            updated_by: userId
          };

      const { data, error } = await supabase.from(FRAMEWORKS).upsert(updates);
      if (error) {
        throw error;
      }
      setFrameworkData(data[0]);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } catch (error) {
      setError(error);
    } finally {
      setSaving(false);
    }
  }

  return { framework, tracks, loading, saving, success, error, setFramework, saveFramework };
}

export default useFramework;
