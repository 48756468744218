import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import "react-quill/dist/quill.snow.css";

import PrivateLayout from 'layouts/Private.js';
import PublicLayout from 'layouts/Public.js';
import { supabase } from './supabase/supabaseClient';
import { SupabaseContextProvider } from 'use-supabase';

import { AppContextProvider } from './contexts/AppContext';


function App () {

  return (
      <SupabaseContextProvider client={supabase}>
        <AppContextProvider>
          <BrowserRouter>
            <Switch>
              <Route path="/app" render={props => <PrivateLayout {...props} />} />
              <Route path="/public" render={props => <PublicLayout {...props} />} />
              <Redirect from="/" to="/app/index" />
            </Switch>
          </BrowserRouter>
          </AppContextProvider>
        </SupabaseContextProvider>
  );
}

export default App;
