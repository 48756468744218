import React from 'react';

export const UserAvatar = ({ displayName, photoUrl, initials, className}) => {

  const avatarClass = className ? 'avatar rounded-circle '+ className : 'avatar rounded-circle avatar-sm';

  if(photoUrl){
    return ( <span className={avatarClass} >
        <img
          alt="..."
          src={photoUrl}
        />
      </span>);
  }
  return (
        <span className={avatarClass}>
          {initials ? initials : displayName?.split(' ')
            .map(x => x.charAt(0))
            .join('')
            .substr(0, 2)
            .toUpperCase()}
        </span>
        );
};




  
