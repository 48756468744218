import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { supabase } from "../supabase/supabaseClient";

import { PLANS } from "constants/databases";

const statusList = [
  { id: "todo", display: "Todo" },
  { id: "doing", display: "In Progress" },
  { id: "done", display: "Done" },
];

function usePlan(accountId, userId, user) {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [plan, setPlan] = useState();

  const initPlan = () => {
    const actions = statusList.reduce(
      (acc, status) => ({ ...acc, [status.id]: [] }),
      {}
    );
    setPlan({ actions: actions });
  };

  useEffect(() => {
    async function loadPlan(accountId, userId) {
      if (!accountId || !userId) {
        return;
      }
      try {
        setLoading(true);
        const { data, error, status } = await supabase
          .from(PLANS)
          .select("*")
          .eq("account_id", accountId)
          .eq("user_id", userId)
          .limit(1)
          .single();

        if (error && status !== 406) {
          throw error;
        }
        if (!data) {
          initPlan();
        } else {
          setPlan(data);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    loadPlan(accountId, userId);
  }, [accountId, userId]);

  async function addAction(action) {
    const update = {
      id: uuidv4(),
      ...action,
      editing: true,
      created_by: action.created_by || userId,
      updated_by: userId,
    };
    const updatedPlan = { ...plan };
    updatedPlan.actions[action.status].push(update);
    setPlan(updatedPlan);
  }

  async function updateAction(action) {
    if (action) {
      const updatedPlan = { ...plan };
      const foundIndex = updatedPlan.actions[action.status].findIndex(
        (i) => i.id === action.id
      );
      updatedPlan.actions[action.status][foundIndex] = action;
      setPlan(updatedPlan);
    }
  }

  async function deleteAction(action) {
    const updatedPlan = { ...plan };
    const filteredActions = updatedPlan.actions[action.status].filter(
      (item) => item.id !== action.id
    );
    updatedPlan.actions[action.status] = filteredActions;
    setPlan(updatedPlan);
  }

  async function savePlan() {
    setSaving(true);
    setSuccess(false);
    const update = plan.id
      ? {
          ...plan,
          updated_at: new Date(),
          updated_by: userId,
        }
      : {
          ...plan,
          account_id: accountId,
          user_id: userId,
          auth_id: user.auth_id,
          updated_by: userId,
          created_by: userId,
        };
    try {
      setLoading(true);
      const { data, error } = await supabase.from(PLANS).upsert(update);
      if (error) {
        throw error;
      }
      setPlan(data[0]);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } catch (error) {
      setError(error);
    } finally {
      setSaving(false);
    }
  }

  return {
    plan,
    statusList,
    setPlan,
    addAction,
    updateAction,
    deleteAction,
    savePlan,
    loading,
    saving,
    success,
    error,
  };
}

export default usePlan;
