export const milestones = [0, 1, 2, 3, 4, 5];

export const titles = {
  levels: [
    {
      level: 1,
      name: "Junior",
    },
    {
      level: 2,
      name: "Confirmé",
    },
    {
      level: 3,
      name: "Sénior",
    },
    {
      level: 4,
      name: "Manager",
    },
    {
      level: 5,
      name: "Manager Sénior",
    },
    {
      level: 6,
      name: "Partner",
    },
  ],
  tracks: [
    {
      name: "Technical",
      titles: [
        {
          level: "C1",
          label: "Consultant Junior",
          description: "Apprends le métier de consultant",
          vision:
            "Encadré(e) par un sénior, tu vas pouvoir naviguer dans différents contextes, voir plusieurs approches, posséder les savoir-faire techniques qui te feront monter en compétence sur le conseil, et partager ta veille et travailler en équipe.",
          wisdom:
            "Points de sagesse qui conditionnent l’entrée dans ce niveau: \n- Adhérer au WHY OCTO.\n - Porter les valeurs OCTO.\n - Ne pas être intimidé(e) par l’ingénierie. \n- Savoir demander de l’aide.\n- Optimiser ton staffing.\n- Respecter tes engagements.\n - Travailler en équipe. \n- Être autonome dans ton apprentissage. \n- T’exprimer de manière claire. \n- Terminer ce que tu entreprends. \n- Prendre le feedback comme une occasion d’apprendre.",
          radar: {
            Mastering: 3,
            Executing: 2,
            Supporting: 3,
            Strengthening: 1,
          },
          minPoints: 0,
          maxPoints: 16,
        },
        {
          level: "C2",
          label: "Consultant Confirmé",
          description: "Assois ton expertise",
          vision:
            "- Tu navigueras entre différents métiers/ postures du conseil IT : Expert technologique, Scrum Master, TechLead, Product Owner, Architecte Logiciel, Architecte SI, Consultant Devops...etc. \n - Tu seras autonome sur au moins une expertise et pour te positionner sur les missions. \n - Tu seras proactif(ve) et capable de sortir de ta zone de confort. \n - Tu commenceras à construire ta bibliothèque de références missions et contextes différents pour t’enrichir, expérimenter le pilotage de mission et contribuer à la synergie des compétences.",
          wisdom:
            "Points de sagesse qui conditionnent l’entrée dans ce niveau: \n - Être autonome sur un sujet d’expertise dans un projet.\n - Avoir une posture “pro” et active et se faire démarquer sur le projet.\n - Prendre des initiatives et être force de proposition sur ton projet.\n - Être capable d’onboarder un nouveau OCTO sur le projet.\n - Être proactif(ve) dans ton staffing.\n - Lever des alertes en cas de problème.\n - Connaître les Droits et Devoirs du consultant.\n - Savoir adapter ta communication.\n - Respecter les processus OCTO ( CRA, 360°, CP...).\n - Savoir reconnaître tes erreurs.\n - Garder ton sang-froid et rester objectif(ve) dans des situations de forte pression. \n - Porter un sujet hors projet (R&D, Capitalisation).\n - Se distinguer comme leader sur dans son projet\n \t- Animer des rituels.\n \t- Contacts hors équipe.\n \t- Challenger des points de méthodo/orga/produit.",
          radar: {
            Mastering: 6,
            Executing: 5,
            Supporting: 5,
            Strengthening: 3,
          },
          minPoints: 17,
          maxPoints: 35,
        },
        {
          level: "C3",
          label: "Consultant Sénior - Référent",
          description: "Gère un projet et partage ton expertise",
          vision:
            "Autonome, tu gèreras une mission de A à Z sur tes domaines de compétence tout en développant ton expérience multisectorielle. Tu seras le/la garant(e) de l’engagement d’une mission de plus de 1M DH. Tu contribueras au BIZ OCTO en faisant des avant-ventes et en étant responsable d’une partie de la proposition commerciale sur ton domaine d’expertise. Enfin, tu partageras à l’interne comme à l’externe et sauras argumenter ton point de vue. Tu seras garant(e) de la capitalisation de tes travaux pour OCTO.",
          wisdom:
            "Points de sagesse qui conditionnent l’entrée dans ce niveau:\n - Incarner les valeurs d’OCTO et contribuer à leur pérennité.\n - Être autonome sur ton projet: \n \t - Pas besoin du niveau d’au-dessus pour mener ta mission.\n \t - Être actif(ve) et autonome pour gérer la relation avec le client.\n - Être ouvert(e) à des points de vue différents.\n - Participer à l’animation d’une communauté.\n - Identifier les leads.\n - Être autonome dans la gestion de ton staffing.\n - Faire passer des entretiens techniques en autonomie sur des niveaux de stagiaire & consultant et en binôme pour des niveaux supérieurs.\n - Avoir une expertise tech identifiée et reconnu dans la tribu / OCTO.\n - Démonter une fiabilité constante: Même appréciation des collègues sur plusieurs missions différentes. \n - Inspirer confiance (client et coéquipiers).\n - Mentorer des personnes de son équipe.\n - Avoir des managés.\n - S’impliquer dans la tribu/OCTO(Porter un sujet tribu/OCTO, Monter une formation...).\n - Diffuser la connaissance(Articles de blog, BoFs...).",
          radar: {
            Mastering: 9,
            Executing: 7,
            Supporting: 8,
            Strengthening: 6,
          },
          minPoints: 36,
          maxPoints: 57,
        },
        {
          level: "C4",
          label: "Manager - Référent",
          description: "Fédère et transmets ton savoir",
          vision:
            "Après avoir expérimenté une grande diversité de types de missions et cumulé des expériences multisectorielles, tu feras profiter les plus juniors de ton expertise technique et de ta compétence conseil. Tu t’impliqueras dans le maintien et le développement du niveau de savoir-faire d’OCTO. Tu contribueras à la conception d’offres originales et gagnantes ayant un fort impact pour OCTO. Tu remonteras des leads en cultivant ton réseau. Tu t’assureras que les activités des Octos dont tu as la charge (missions, formations, R&D) sont en ligne avec les objectifs de développement d’OCTO. Tu guideras, orienteras, accompagneras avec succès. Tu feras grandir par des feedbacks réguliers et constructifs. Ton engagement dans la communauté se verra en interne et/ou en externe.",
          wisdom:
            "Points de sagesse qui conditionnent l’entrée dans ce niveau: - Être un(e) optimiste convaincu(e). \n - Être garant(e) des résultats attendus. \n - Savoir gérer la relation client.\n - Être moteur dans l’animation de la communauté d’Octos et/ou rayonner à l’externe.\n - Être porteur(teuse) de la culture OCTO, transmettre les valeurs et le WHY d’OCTO.\n - Faire passer des entretiens techniques en autonomie. \n - Être référent(e) et faire grandir des Octos.",
          radar: {
            Mastering: 12,
            Executing: 8,
            Supporting: 11,
            Strengthening: 9,
          },
          minPoints: 58,
          maxPoints: 89,
        },
        {
          level: "C5",
          label: " Manager Sénior - Référent",
          description: "Deviens un ambassadeur OCTO",
          vision:
            "Tu contribueras au rayonnement interne/ externe d’OCTO et à l’évolution de la stratégie OCTO en anticipant les évolutions du monde de l’IT. La communauté te reconnaîtra comme un(e) sachant(e). Tu fédèreras autour de sujets innovants et construiras l’offre associée.",
          wisdom:
            "Points de sagesse qui conditionnent l’entrée dans ce niveau: \n - Porter une communauté interne. Piloter la contribution d’autres Octos.\n - Être référent(e) en interne et/ou à l’extérieur sur au moins une expertise (conférences, post de blog porteur d’une vision, petits-déjeuners OCTO, ...).\n - Faire preuve d’assertivité, savoir convaincre et innover.\n - Avoir l’intelligence situationnelle.\n - Transmettre ton savoir via différents canaux (BOF, mailing list, formation, ...).\n - Démontrer que tu te sens concerné(e) parle devenir d’OCTO : communiquer, identifier de futurs Octos séniors, ...",
          radar: {
            Mastering: 15,
            Executing: 9,
            Supporting: 12,
            Strengthening: 11,
          },
          minPoints: 90,
          maxPoints: 134,
        },
        {
          level: "C6",
          label: "Partner - Référent",
          description: "Mène la communauté vers de nouveaux sommets",
          vision:
            "En tant qu’associé(e), tu seras un(e) consultant(e) accompli(e), un rôle model, un(e) leader(euse) et un(e) porte-parole de la communauté. Tu seras collectivement responsable, avec tes pairs, du bon fonctionnement, de la culture, de la stratégie et du développement d’OCTO. Tu engageras la société vis-à-vis des tiers. Tu as prouvé ton engagement par rapport à la communauté. Tu as prouvé ta capacité à dépasser les intérêts locaux.",
          wisdom:
            "Points de sagesse qui conditionnent l’entrée dans ce niveau: \n - Être visionnaire, porter une originalité qui fonde la position d’OCTO vis-à-vis de ses clients et partenaires.\n - Contribuer fortement, de manière directe ou indirecte, à la performance économique d’OCTO.\n - Être à l’aise comme interlocuteur(trice) naturel(le) des dirigeants de nos clients (CEO, CIO, etc.).\n - Démontrer les valeurs d’OCTO.\n - Adopter une posture de leader pour manager et inspirer un grand nombre d’OCTOS. \n - Être élu(e) et coopté(e) par le cercle des Associés.",
          radar: {
            Mastering: 15,
            Executing: 9,
            Supporting: 13,
            Strengthening: 13,
          },
          minPoints: 135,
        },
      ],
    },
    {
      name: "Managerial",
      titles: [
        {},
        {},
        {
          level: "M3",
          label: "Consultant Sénior",
          description: "Gère un projet et partage ton expertise",
          vision:
            "Autonome, tu gèreras une mission de A à Z sur tes domaines de compétence tout en développant ton expérience multisectorielle. Tu seras le/la garant(e) de l’engagement d’une mission de plus de 1M DH. Tu contribueras au BIZ OCTO en faisant des avant-ventes et en étant responsable d’une partie de la proposition commerciale sur ton domaine d’expertise. Enfin, tu partageras à l’interne comme à l’externe et sauras argumenter ton point de vue. Tu seras garant(e) de la capitalisation de tes travaux pour OCTO.",
          wisdom:
            "Points de sagesse qui conditionnent l’entrée dans ce niveau:\n - Incarner les valeurs d’OCTO et contribuer à leur pérennité.\n - Être autonome sur ton projet: \n \t - Pas besoin du niveau d’au-dessus pour mener ta mission.\n \t - Être actif(ve) et autonome pour gérer la relation avec le client.\n - Être ouvert(e) à des points de vue différents.\n - Participer à l’animation d’une communauté.\n - Identifier les leads.\n - Être autonome dans la gestion de ton staffing.\n - Faire passer des entretiens techniques en autonomie sur des niveaux de stagiaire & consultant et en binôme pour des niveaux supérieurs.\n - Avoir une expertise tech identifiée et reconnu dans la tribu / OCTO.\n - Démonter une fiabilité constante: Même appréciation des collègues sur plusieurs missions différentes. \n - Inspirer confiance (client et coéquipiers).\n - Mentorer des personnes de son équipe.\n - Avoir des managés.\n - S’impliquer dans la tribu/OCTO(Porter un sujet tribu/OCTO, Monter une formation...).\n - Diffuser la connaissance(Articles de blog, BoFs...).",
          radar: {
            Mastering: 5,
            Executing: 9,
            Supporting: 9,
            Strengthening: 7,
          },
          minPoints: 36,
          maxPoints: 57,
        },
        {
          level: "M4",
          label: "Manager",
          description: "Fédère et développe la relation client",
          vision:
            "Que tu sois issu(e) de la filière biz dev ou consultant, ta responsabilité sera principalement engagée dans le développement d’OCTO tout en veillant à l’épanouissement des Octos. Tu sauras suggérer des missions et accompagner les clients sur leurs enjeux stratégiques en phase avec les expertises d’OCTO. Tu participeras activement au développement du biz et à la capitalisation en rayonnant à partir de tes missions. Tu t’assureras que les activités des Octos dont tu as la charge (missions, formations, R&D) sont en ligne avec leur potentiel et leur désir d’évolution ainsi qu’avec les objectifs de développement d’OCTO. Ton engagement dans la communauté se verra en interne et/ou en externe.",
          wisdom:
            "Points de sagesse qui conditionnent l’entrée dans ce niveau: \n - Être un(e) optimiste convaincu(e). \n - Être garant(e) des résultats attendus. \n - Savoir gérer la relation client.\n - Être moteur dans l’animation de la communauté d’Octos et/ou rayonner à l’externe.\n - Être porteur(teuse) de la culture OCTO, transmettre les valeurs et le WHY d’OCTO.\n - Faire passer des entretiens opérationnels en autonomie.\n - Être référent(e) et faire grandir des Octos.",
          radar: {
            Mastering: 6,
            Executing: 12,
            Supporting: 11,
            Strengthening: 9,
          },
          minPoints: 58,
          maxPoints: 89,
        },
        {
          level: "M5",
          label: "Manager Sénior",
          description: "Deviens un ambassadeur OCTO",
          vision:
            "Tu seras un pilier du Biz OCTO. Tu contribueras au rayonnement interne/externe d’OCTO et à l’évolution de la stratégie d’OCTO en anticipant les évolutions du monde de l’IT. La communauté te reconnaîtra comme un(e) sachant(e).",
          wisdom:
            "Points de sagesse qui conditionnent l’entrée dans ce niveau: \n - Porter à plusieurs un compte clé.\n - Transmettre ton savoir via différents canaux (BOF, mailing list, formation...).\n - Piloter la contribution d’autres Octos.\n - Démontrer que tu te sens concerné(e) par le devenir d’OCTO : communiquer, identifier de futurs Octos sénior...\n - Contribuer à la conception d’offres originales et gagnantes qui ont un fort impact pour OCTO.",
          radar: {
            Mastering: 7,
            Executing: 15,
            Supporting: 13,
            Strengthening: 11,
          },
          minPoints: 90,
          maxPoints: 134,
        },
        {
          level: "M6",
          label: "Partner",
          description: "Mène la communauté vers de nouveaux sommets",
          vision:
            "En tant qu’associé(e), tu seras un(e) consultant(e) accompli(e), un rôle model, un(e) leader(euse) et un(e) porte-parole de la communauté. Tu seras collectivement responsable, avec tes pairs, du bon fonctionnement, de la culture, de la stratégie et du développement d’OCTO. Tu engageras la société vis-à-vis des tiers. Tu as prouvé ton engagement par rapport à la communauté. Tu as prouvé ta capacité à dépasser les intérêts locaux.",
          wisdom:
            "Points de sagesse qui conditionnent l’entrée dans ce niveau: \n - Être visionnaire, porter une originalité qui fonde la position d’OCTO vis-à-vis de ses clients et partenaires.\n - Contribuer fortement, de manière directe ou indirecte, à la performance économique d’OCTO.\n - Être à l’aise comme interlocuteur(trice) naturel(le) des dirigeants de nos clients (CEO, CIO, etc.).\n - Démontrer les valeurs d’OCTO.\n - Adopter une posture de leader pour manager et inspirer un grand nombre d’OCTOS. \n - Être élu(e) et coopté(e) par le cercle des Associés.",
          radar: {
            Mastering: 7,
            Executing: 15,
            Supporting: 15,
            Strengthening: 13,
          },
          minPoints: 135,
        },
      ],
    },
  ],
};

export const getTitlesOptions = () => {
  let titleList = [];
  titles.tracks.forEach((track) => {
    const trackTitles = track.titles
      .filter((title) => title.level)
      .map((title) => {
        return {
          value: title.level,
          label: title.label,
        };
      });

    titleList = [...titleList, ...trackTitles];
  });

  return titleList;
};

export const getTitleById = (level) => {
  let title = undefined;
  titles.tracks.forEach((track) => {
    title= title || track.titles.find((title) => title.level === level);
  });

  return title;
};
