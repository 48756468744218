import React from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
} from "reactstrap";

import UserHeader from "components/Headers/UserHeader.js";
import BasicProfile from "./BasicProfile";

import useProfile from "../../hooks/useProfile";
import useMyLevel from "../../hooks/useMyLevel";

import AssessmentOverview from "../Assessments/AssessmentOverview";

import { useAppContext } from "contexts/AppContext";

function UserProfile() {
  const { id } = useParams();
  const { user, account } = useAppContext();
  const userId = id || user?.id;
  const { profile } = useProfile(userId);
  const { assessment, loading, error } = useMyLevel(account?.id, userId);


  return (
    <>
      <UserHeader user={profile} />
      <Container className="mt--9" fluid>
        <Row>
          <Col className="order-xl-1 mb-5 mb-xl-0" xl="4">
            {profile && <BasicProfile profile={profile} />}
          </Col>
          <Col className="order-xl-2" xl="8">
            <AssessmentOverview user={profile} assessment={assessment} loading={loading} error={error} actionVisible={false}/>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UserProfile;
