import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { InputGroupText, InputGroup } from "reactstrap";

import { supabase } from "supabase/supabaseClient";
import { USERS } from "constants/databases";

const formSelectStyles = {
  control: (base) => ({
    ...base,
    color: "#8898aa",
    backgroundColor: "transparent",
    border: "none",
    marginTop: "-.4rem",
    boxShadow: 'none'
  }),
  singleValue: (base) => ({
    ...base,
    color: "#8898aa"
  }),
  option: (base) => ({
    ...base,
    border: `1px solid #f2f8fd`,
    padding: "1rem 1.5rem",
    fontSize: "1.1rem",
    height: '100%',
  }),
};

const { Option } = components;
const IconOption = props => (
  <Option {...props}>
      <span className="avatar avatar-xs rounded-circle bg-blue mr-3">
        {props.data.code}
      </span>
    {props.data.label}
  </Option>
);

function SearchBox({account, onSelect=()=>{}}) {
  const [ selectedValue, setSelectedValue ] = useState();

  function handleSelect(e) {
    onSelect(e);
    setSelectedValue(null);
  }

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(searchUsers(inputValue));
    });

  async function searchUsers(keyword) {
    if(keyword?.length < 3) return [];

    const search = "%" + keyword + "%";
    try {
      let query = supabase
        .from(USERS)
        .select(`id, name, code, email, role, manager, status`)
        .eq("account_id", account?.id)
        .or('name.ilike.'+search+',code.ilike.'+search);

      const { data, error, status } = await query;
      if (error && status !== 406) {
        throw error;
      }

      if (data) {
        return data?.map((user) => ({ value: user.id, label: user.name, code: user.code }));
      }
    } catch (error) {
      return [];
    }
  }

  return (
    <>
      <div className="navbar-search navbar-search-light form-inline mr-3 align-middle">
        <InputGroup className="input-group-alternative">
          <InputGroupText>
            <i className="fas fa-search" />
          </InputGroupText>
          <AsyncSelect
            className="form-control"
            value={selectedValue}
            components={{ Option: IconOption, DropdownIndicator:() => null, IndicatorSeparator:() => null, NoOptionsMessage:() => null }}
            styles={formSelectStyles}
            cacheOptions
            defaultOptions
            loadOptions={promiseOptions}
            placeholder="Search..."
            openMenuOnClick={false}
            onChange={(e) => handleSelect(e)}
          />
        </InputGroup>
      </div>
    </>
  );
}

export default SearchBox;
