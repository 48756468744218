import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";

function ZoomableCirclePack({data, width, height}) {
  
  if(!data || !width || !height) {
    return null;
 }


 const svgRef = useRef();
  
  useEffect(() => {
    let color = d3
      .scaleLinear()
      .domain([0, 5])
      .range(["hsl(231,90%,100%)", "hsl(231,55%,75%)" ])
      .interpolate(d3.interpolateHcl);

    let pack = (data) =>
      d3.pack().size([width, height]).padding(15)(
        d3
          .hierarchy(data)
          .sum((d) => d.value)
          .sort((a, b) => b.value - a.value)
      );

    var root = pack(data);

    let focus = root;
    let view;

    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", [
        //we are just adding space (the +100) between the circles and the edges so that nothing gets cut off
        -(width + 50) / 2,
        -(height + 50) / 2,
        width + 50,
        height + 50,
      ])
      //change the cursor to pointer when on the svg
      .style("cursor", "pointer")
      //set the background color of the svg
      .style("background-color", "#FFF")
      //when we click on the background, zoom to the root
      .on("click", (event) => zoom(event, root));

    //slice out the parent object (this object is used to contain all the roots and isn't an actual node)
    var slicedData = root.descendants().slice(1);
    svg.selectAll("*").remove();

    const node = svg
      .append("g")
      .attr("id", "circles")
      .selectAll("circle")
      .data(slicedData)
      .join("circle")
      .attr("pointer-events", (d) => (!d.children ? "none" : null))
      .attr("fill", (d) => {
        if (d.children) {
          let col = color(d.depth);
          return col;
        }
        else if (d.data.type==="need") {
          //if the circle is need to be filledf, make it another color
          return "#fac710";
        }
        else {
          //if the circle is a leaf, make it final color
          return "#13cdd4";
        }
      })
      .style("display", "block")
      .on("mouseover", function () {
        //when we mouse over a circle, add an outline around it
        d3.select(this).attr("stroke", "#000000");
      })
      .on("mouseout", function () {
        //when we take our mouse off of a circle, remove the outline from it
        d3.select(this).attr("stroke", null);
      })
      .on(
        "click",
        (event, d) => focus !== d && (zoom(event, d), event.stopPropagation())
      )
      .on(
        //when we double click, open google
        "dblclick",
        () => {}
      );

    //adding a grouping for all labels
    var label = svg
      .append("g")
      //don't let the label interfere with clicking the circles (we can click through the text)
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .selectAll("text")
      //for each descendant of the root, we're going to create a label
      .data(root.descendants())
      .join("text")
     // .style("font", () => "25px sans-serif")
      .style("font-size", (d) => ((d.children == null) ? "10px" : (d.parent === root) ? "14px": "11px"))
      .style("font-weight", (d) => ((d.children == null) ? "normal" :  "bold"))
      .style("fill-opacity", (d) => (d.children == null ? 0.8 : 0.8))
      .style("display",  "inline")
      .text((d) => d.data.title);

  

    //initial zoom
    zoomTo([root.x, root.y, root.r  ]);
    

    function zoomTo(v) {
      const k = (width / v[2]) * 0.5;
      view = v;

      label.attr("transform", (d) =>
        //if our circle has children, we put the label on top of the circle. If not, we put it in the middle
        d.children == null
          ? `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k })`
          : (d.parent === root) ? `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k - d.r * k - 10})`
          :`translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k - d.r * k + 10})`
      );

      node.attr(
        "transform",
        (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`
      );

     //node.attr("r", (d) => d.r * k);
     node.attr("r", (d) => (d.children == null ? d.r * k - 5 : d.r * k + 5));
    }

    function zoom(event, d) {
      focus = d;
      const transition = svg
        .transition()
        .duration(event.altKey ? 7500 : 750)
        .tween("zoom", () => {
          const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
          return (t) => zoomTo(i(t));
        });

    }
  });
  return <svg ref={svgRef} width={width} height={height}></svg>;
}


export default ZoomableCirclePack;