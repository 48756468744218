// @flow

import React from "react";
import { Row, Col, Container, Button, Table } from "reactstrap";

function TitleSelector({ titles, selectedTitle, currentTitle, onSelectTitle }) {
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="border-0">
          <tr >
          <th className="border-0"></th>
          {titles.levels.map((level) => {
            return (
              <th scope="col" key={level.level} className="text-center border-0 pr-1">
                {level.name}
              </th>
            );
          })}
          </tr>
        </thead>
        <tbody>
          {titles.tracks.map((track) => {
            return (
              <tr key={track.name}>
                <th scope="row" className="border-0 text-xs">{track.name}</th>
                {track.titles.map((title) => {
                  return (
                    <td key={title.level} className="pr-1">
                      <div className="d-flex align-items-center">
                        {title.level && (
                          <Button
                            style={{ width: "100%" }}
                            className="border-0 shadow-none text-xs"
                            color={
                              selectedTitle?.level === title.level
                                ? "primary"
                                : "default"
                            }
                            onClick={() => onSelectTitle(title)}
                          >
                            {title.label}
                          </Button>
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      {/* <Container fluid>
        {titles.map((track) => {
          return (
            <Row
              key={track.track}
              className="fluid mb-3 text-center p-2 text-xs"
              style={{ background: "#dfebf6" }}
            >
              {track.track}
              {track.titles.map((title) => {
                return (
                  <Col
                    key={title.level}
                    className="p-1"
                    style={{ width: "100%" }}
                  >
                    {title.level && (
                      <Button
                        style={{ width: "100%" }}
                        className="border-0 shadow-none text-xs"
                        color={
                          selectedTitle?.level === title.level
                            ? "primary"
                            : "default"
                        }
                        onClick={() => onSelectTitle(title)}
                      >
                        {title.label}
                      </Button>
                    )}
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </Container> */}
    </>
  );
}

export default TitleSelector;
