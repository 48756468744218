import React, { useState } from "react";

import {
  Card,
  Col,
  CardBody,
  Progress,
  Table,
  Row,
  Spinner,
} from "reactstrap";

import StatusBadge from "components/StatusBage";
import ActionsProgress from "./ActionsProgress";

export default function MyStatus({ user }) {
 
  return (
    <Card className="bg-gradient-default">
      <CardBody>
        <Row>
          <Col className="pb-4">
            <small className="text-light">My Assessment Status</small>
            <StatusBadge status={user?.assessments[0]?.status} />
          </Col>
        </Row>
        <Row>
          <Col className="col">
            <small className="text-light">My Actions</small>
            <ActionsProgress actions={user?.plans[0]?.actions} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
