import React, { useContext, useState, useEffect } from 'react';
import { supabase } from '../supabase/supabaseClient';

import { USERS, ACCOUNTS } from 'constants/databases';

const AppContext = React.createContext();

export function useAppContext () {
  return useContext(AppContext);
}

export function AppContextProvider ({ children }) {
  const [authUser, setAuthUser] = useState();
  const [user, setUser] = useState();
  const [account, setAccount] = useState();
  const [lang, setLang] = useState('En');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setAuthUser(supabase.auth?.user());
    setLoading(false);

    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        setAuthUser(session?.user ?? null);
        setLoading(false);
      }
    );

    return () => {
      listener && listener.unsubscribe();
    };
  }, []);

  useEffect(() => {
    loadProfile(authUser?.id);    
  }, [authUser]);

  async function loadProfile (userId) {
    if(!userId) {
      setUser(null);
      return;
    }
    try {
      setLoading(true);
      const { data, error, status } = await supabase
        .from(USERS)
        .select(`*`)
        .eq('auth_id', userId)
        .single();

      if (error && status !== 406) {
        throw error;
      }

      if (data) {
        setUser(
          {
            level : 2,
            ...data,
          });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadAccount(user?.account_id);
  }, [user]);

  async function loadAccount (accountId) {
    if (!accountId) {
      return;
    }
    try {
      setLoading(true);
    
      const { data, error, status } = await supabase
        .from(ACCOUNTS)
        .select('*')
        .eq('id', accountId)
        .limit(1)
        .single();

      if (error && status !== 406) {
        throw error;
      }

      if (data) {
        setAccount(data);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }


  const value = {
    signUp: (data) => supabase.auth.signUp(data),
    signIn: (data) => supabase.auth.signIn(data),
    signOut: () => supabase.auth.signOut(),
    setLang,
    user,
    authUser,
    account,
    loading,
    lang
  };

  return (
    <AppContext.Provider value={value}>
      {!loading && children}
    </AppContext.Provider>
  );
}