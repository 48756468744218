import React from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

import { milestones } from "constants/data";

import RichEditor from "components/RichEditor";

function SkillAssessmentEditor({
  skillDefintion,
  currentLevel = 0,
  updateLevel,
}) {
  if (!skillDefintion || !skillDefintion.track) {
    return null;
  }

  const track = skillDefintion.track;
  const currentMilestone = track.milestones[currentLevel - 1];

  return (
    <>
      <style>{`
          .background-line {
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(233,233,239,1) 51%, rgba(255,255,255,0) 57%, rgba(255,255,255,0) 100%);
          }
        `}</style>
      <Card className="border-0" style={{ minHeight: "700px" }}>
        <CardHeader>
        <Row className="background-line mb-3">
            {milestones.map((milestone) => {
              const isMet = milestone <= currentLevel;
              return (
                <Col key={milestone}>
                  <span
                    className="avatar rounded-circle avatar-sm"
                    onClick={() => updateLevel(track.id, milestone)}
                    style={{
                      border: `4px solid ${
                        milestone === currentLevel
                          ? "#000"
                          : isMet
                          ? skillDefintion.color
                          : "#eee"
                      }`,
                      background: isMet ? skillDefintion.color : undefined,
                      cursor: "pointer",
                    }}
                  >
                    {milestone}
                  </span>
                </Col>
              );
            })}
          </Row>
          <h3 className="h3 title">
            <span className="d-block mb-1">{track.displayName}</span>
            <small className="h4 font-weight-light text-muted">
              {track.description}
            </small>
          </h3>
        </CardHeader>
        <CardBody>
          {currentMilestone && (
            <RichEditor readOnly={true} content={currentMilestone.content} />
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default SkillAssessmentEditor;
