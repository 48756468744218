import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import BoardColumn from './BoardColumn';

import {
  Row,
  Col
} from 'reactstrap';

const getFiltredItems = (items, filter) => {
  return filter ? items.filter(it => it.tags?.some(tag => tag.toUpperCase() === filter.toUpperCase())) : items;
};

const removeFromList = (list, index) => {
  const result = Array.from(list);
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

const addToList = (list, index, element) => {
  const result = Array.from(list);
  result.splice(index, 0, element);
  return result;
};

function PlanBacklog ({ filter, plan, tracks, statusList, setPlan, addAction, updateAction, deleteAction, readOnly }) {
  if (!plan || !statusList) {
    return null;
  }
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const listCopy = { ...plan.actions };
    const sourceList = listCopy[result.source.droppableId];
    const [removedElement, newSourceList] = removeFromList(
      sourceList,
      result.source.index
    );
    listCopy[result.source.droppableId] = newSourceList;
    const destinationList = listCopy[result.destination.droppableId];
    listCopy[result.destination.droppableId] = addToList(
      destinationList,
      result.destination.index,
      removedElement
    );

    setPlan({ ...plan, actions: listCopy });
  };

  return (
    <Row>
      <DragDropContext onDragEnd={onDragEnd}>
          {statusList?.map((status) => (
            <Col key={status.id}>
                <BoardColumn
                    status={status}
                    elements={getFiltredItems(plan.actions[status.id], filter)}
                    addItem={addAction}
                    updateItem={updateAction}
                    deleteItem={deleteAction}
                    tracks={tracks}
                    activeFilter={filter}
                    readOnly={readOnly}
                />
            </Col>
          ))}
      </DragDropContext>
    </Row>
  );
}

export default PlanBacklog;
