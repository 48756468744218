import React, { useState, useEffect, useRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import ReactQuill from 'react-quill';
import TagsInput from 'react-tagsinput';
import { Button, Col, Row, Card, CardBody, Badge } from 'reactstrap';

const Action = ({ item, index, isEditing, tracks, updateItem, deleteItem, readOnly }) => {
  const wrapperRef = useRef(null);
  const [editing, setEditing] = useState(isEditing || item.editing);

  useEffect(
    (wrapperRef) => {
      document.addEventListener('click', handleClickOutside, false);
      return () => {
        document.removeEventListener('click', handleClickOutside, false);
      };
    },
    [wrapperRef]
  );

  const getTagColor = (tag) => {
    const track = tracks?.filter(tr => tr.displayName?.toUpperCase() === tag?.toUpperCase());
    const color = (track && track.length > 0) ? track[0].color : 'primary';
    return color;
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setEditing(false);
    }
  };

  function handleOnChange (content, delta, source) {
    if (source === 'user') {
      const updated =  { ...item, content: content, editing: false };
      updateItem(updated);
    }
  }

  function updateActionTags (data) {
    const updated = { ...item, tags: data };
    updateItem(updated);
  }

  const toolbarOptions = editing
    ? [
        [{ header: [2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link']

      ]
    : false;

  return (
    <Draggable draggableId={item.id} index={index} isDragDisabled={readOnly}>
      {(provided, snapshot) => {
        return (
          <div
            className="mb-2"
            style={{
              display: 'flex',
              columnGap: '10px',
              flexDirection: 'column'
            }}
            ref={provided.innerRef}
            snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            >
            <div ref={wrapperRef}>
              <Card
                style={{ overflowY: 'auto', maxHeight: editing ? '800px' : '200px' }}
                onDoubleClick={() => setEditing(true)}
              >
                <CardBody>
                  <Row>
                    <Col className="col ml--2">
                      <ReactQuill
                        theme="snow"
                        placeholder= 'Add description...'
                        readOnly={!editing}
                        modules={{ toolbar: toolbarOptions }}
                        value={item.content}
                        onChange={handleOnChange}
                      />
                      {
                        editing
                          ? <>
                            <TagsInput
                                onlyUnique
                                disabled={!editing}
                                className="bootstrap-tagsinput ml-2 pt-2 pb-2"
                                onChange={updateActionTags}
                                value={item?.tags}
                                tagProps={{ className: 'tag badge badge-sm badge-info mr-1 ml-2' }}
                                inputProps={{
                                  className: 'text-xs text-muted',
                                  placeholder: 'Add tag...'
                                }}
                            />
                            <Button
                                color="default"
                                className="float-right border-0"
                                href="#edit"
                                outline
                                onClick={() => deleteItem(item)}
                                size="sm"
                              >
                              <i className="fas fa-trash" />
                            </Button>
                          </>
                          : item?.tags?.map(function (t) {
                            return (
                              <Badge
                                color={getTagColor(t)}
                                key={t}
                                className="mr-1 ml-2 text-white"
                                style={{
                                  borderRadius: '.15rem',
                                  opacity: '70%',
                                  background: getTagColor(t)
                                }}
                              >
                                {t}
                              </Badge>
                            );
                          })
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default Action;
