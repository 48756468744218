import React from "react";

import { Container, Row, Col } from "reactstrap";
import { useUser } from "use-supabase";

import Header from "components/Headers/Header.js";

import AccountSidebar from "./AccountSidebar";
import CompanyProfile from "./CompanyProfile";
import useAccount from "../../hooks/useAccount";
import { useAppContext } from "../../contexts/AppContext";

function Company() {
  const { user, account } = useAppContext();
  const { createAccount, updateAccount } = useAccount();

  return (
    <>
      <Header />
      <Container fluid>
        <Row>
          <Col xl="3">
            <AccountSidebar company={account} />
          </Col>
          <Col className="mb-5 mb-xl-0" xl="9">
            <CompanyProfile
              company={account}
              userId={user?.auth_id}
              createAccount={createAccount}
              updateAccount={updateAccount}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Company;
