import React from 'react';
import {
  Container,
  Row,
  Col
} from 'reactstrap';

import Header from 'components/Headers/Header.js';
import AccountSidebar from './AccountSidebar';
import AccountMembers from './AccountMembers';

import { useAppContext } from '../../contexts/AppContext';


function Users () {
  const { user, account } = useAppContext();

  if (!user || !account) {
    return null;
  }
  return (
      <>
        <Header/>
          <Container fluid>
        <Row>
            <Col xl="3">
              <AccountSidebar company={account}/>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="9">
              <AccountMembers user={user} account={account}/>
            </Col>
          </Row>
        </Container>
      </>
  );
}

export default Users;
