// @flow

import React from 'react';
import * as d3 from 'd3';
import { milestones } from 'constants/data';

const width = 400;
const arcMilestones = milestones.slice(1); // we'll draw the '0' milestone with a circle, not an arc.

const truncate = (input, length) => input && input.length > length ? `${input.substring(0, length)}` : input;

function NightingaleChart ({ skills, selectedSkillId, handleLevelChange=()=>{} }) {
  if (!skills) {
    return null;
  }
  const currentMilestoneId = skills.find(x => x.skillId === selectedSkillId);

  const radiusScale = d3.scaleBand()
    .domain(arcMilestones)
    .range([0.15 * width, 0.45 * width])
    .paddingInner(0.1);

  const arcFn = d3.arc()
    .innerRadius(milestone => radiusScale(milestone))
    .outerRadius(milestone => radiusScale(milestone) + radiusScale.bandwidth())
    .startAngle(-Math.PI / skills.length)
    .endAngle(Math.PI / skills.length)
    .padAngle(Math.PI / 200)
    .padRadius(0.45 * width)
    .cornerRadius(2);

  return (
      <figure>
        <style>{`
          figure {
            margin: 0;
          }
          .skill-milestone {
            fill: #eee;
            cursor: pointer;
          }
          .skill-milestone-current, .skill-milestone:hover {
            stroke: #000;
            stroke-width: 4px;
            stroke-linejoin: round;
          }
          .label-xs {
            font-size: 0.55rem !important;
          }
        `}</style>
        <svg style={{ width: width, height: width }}>
          <g transform={`translate(${width / 2},${width / 2}) rotate(-33.75)`}>
            {skills.map((skill, i) => {
              const isCurrentTrack = skill.skillId === selectedSkillId;
              return (
                <g key={skill.skillId} transform={`rotate(${i * 360 / skills.length})`}>
                  {arcMilestones.map((milestone, index) => {
                    const isCurrentMilestone = isCurrentTrack && milestone === currentMilestoneId;
                    const isMet = skill.level >= milestone || milestone === 0;
                    return (
                      <React.Fragment key={skill.skillId + '-' + milestone} >
                      <path
                          id={skill.skillId + '-' + milestone}
                          className={'skill-milestone ' + (isMet ? 'is-met ' : ' ') + (isCurrentMilestone ? 'skill-milestone-current' : '')}
                          onClick={() => handleLevelChange(skill.skillId, milestone)}
                          d={arcFn(milestone)}
                          style={{ fill: isMet ? skill.color : undefined }} />
                          {
                            (index === arcMilestones.length - 1) &&
                              <text
                                className="label-xs"
                                dy="-10px"
                                >
                                  <textPath
                                    style={{ textAnchor: 'middle', fill: skill.color, marginBottom: '3px' }}
                                    startOffset="20%"
                                    xlinkHref={'#' + skill.skillId + '-' + milestone}
                                  >{truncate(skill.skillName, 13)}</textPath>
                              </text>
                          }
                        </React.Fragment>
                    );
                  })}
                  <circle
                      r="8"
                      cx="0"
                      cy="-50"
                      style={{ fill: skill.color }}
                      className={'skill-milestone ' + (isCurrentTrack && !currentMilestoneId ? 'skill-milestone-current' : '')}
                      onClick={() => handleLevelChange(skill.skillId, 0)} />
                </g>
              );
            })}
          </g>
        </svg>
      </figure>
  );
}

export default NightingaleChart;
