import React from 'react';

function SkillSelector ({ progression, selectedSkill, handleSelectSkill }) {
  const categories = [];
  progression.reduce(function (res, value) {
    if (!res[value.categoryId]) {
      res[value.categoryId] = { categoryId: value.categoryId, categoryName: value.categoryName, color: value.color, skillsCount: 0 };
      categories.push(res[value.categoryId]);
    }
    res[value.categoryId].skillsCount += 1;
    return res;
  }, {});

  return (
      <table className="skill-selector-table">
        <style>{`
          .skill-selector-table {
            border-collapse: separate;
            width: 100%;
            border-spacing: 3px;
            border-bottom: 1px solid #ccc;
            padding-bottom: 20px;
            margin-bottom: 20px;
            margin-left: -3px;
          }
          .skill-selector-value {
            color: black;
            line-height: 50px;
            width: 4rem;
            height: 3rem;
            text-align: center;
            background: #eee;
            font-weight: bold;
            font-size: 24px;
            border-radius: 3px;
            cursor: pointer;
          }
          .skill-selector-label {
            text-align: center;
            font-size: 11px;
          }
        `}</style>
        <tbody>
          <tr>
            {

              progression?.map(skill => (
                <td key={'label-' + skill.skillId} className="skill-selector-label" onClick={() => handleSelectTrack(skill.skillId)}>
                  {skill.skillName}
                </td>
              ))

            }
          </tr>
          <tr>

            {
              progression?.map(skill => (
                <td key={'value-' + skill.skillId} className="skill-selector-value"
                style={{ border: '4px solid ' + (skill.skillId === selectedSkill?.skillId ? '#000' : skill.color), background: skill.color }}
                onClick={() => handleSelectSkill(skill.skillId)}>
                  {skill.level}
                </td>
              ))
            }
          </tr>
          <tr>
            {
              categories?.map(s => (
                  <th key={s.categoryId} colSpan={s.skillsCount} className="skill-selector-label">
                    <h5 style={{ color: s.color }}>{s.categoryName}</h5>
                  </th>
              ))
              }
          </tr>
        </tbody>
      </table>
  );
}

export default SkillSelector;
