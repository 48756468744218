import React, { useState } from "react";

import {
  Card,
  Button,
  CardHeader,
  Media,
  Table,
  Row,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";

import ConfirmModal from "components/ConfirmModal";
import useAssessmentsHistory from "hooks/useAssessmentsHistory";

export default function AssessmentsHistory({ user, account }) {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [toDelete, setToDelete] = useState(null);

  const { assessments, deleteAssessment, loading, error } = useAssessmentsHistory(
    account?.id,
    user?.id
  );

  function toggleModal() {
    setConfirmModalOpen(!confirmModalOpen);
  }

  function confirmDelete(id) {
    setToDelete(id);
    toggleModal();
  }

  function onDelete() {
    if (toDelete) {
      deleteAssessment(toDelete).then(() => {
        toggleModal();
        setToDelete(null);
      });
    }
  }

  function getStatusLabel(status) {
    switch (status) {
      case "draft":
        return "In Progress";
      case "submitted":
        return "In Review";
      case "reviewed":
        return "To Approve";
      case "approved":
        return "Approved";
      case "archived":
        return "Archived";
      default:
        return "";
    }
  }

  if (loading) {
    return (
      <Card className="shadow">
        <div className="d-flex justify-content-center p-9">
          <Spinner> </Spinner>
        </div>
      </Card>
    );
  }
  if (error) {
    return (
      <Card className="shadow">
        <div className="text-center justify-content-center ml-9 mr-9">
          <UncontrolledAlert color="danger" closeClassName="notif-close-btn">
            <span className="alert-text ml-1">{error}</span>
          </UncontrolledAlert>
        </div>
      </Card>
    );
  }
  return (
    <Card className="shadow mb-3">
      <ConfirmModal
        isOpen={confirmModalOpen}
        onConfirm={onDelete}
        onCancel={toggleModal}
      />
      <CardHeader>
        <Row className="align-items-center">
          <div className="col">
            <h3 className="mb-0">Assessments History</h3>
          </div>
        </Row>
      </CardHeader>
      {assessments?.length > 0 ? (
        <Table className="align-items-center table-flush" responsive>
          <tbody>
            {assessments.map(function (assessment) {
              return (
                <tr key={assessment.id}>
                  <th scope="row">
                    <Media className="align-items-center">
                      {assessment.status === "draft" && (
                        <span className="avatar avatar-sm rounded-circle bg-blue">
                          <i className="fa-regular fa-clock"></i>
                        </span>
                      )}
                      {assessment.status === "submitted" && (
                        <span className="avatar avatar-sm rounded-circle bg-warning">
                          <i className="ni ni-send"></i>
                        </span>
                      )}
                      {assessment.status === "approved" && (
                        <span className="avatar avatar-sm rounded-circle bg-green">
                          <i className="ni ni-check-bold "></i>
                        </span>
                      )}
                      {assessment.status === "archived" && (
                        <span className="avatar avatar-sm rounded-circle bg-gray">
                          <i className="ni ni-archive-2 "></i>
                        </span>
                      )}
                      <div className="col ml--2">
                        <h4 className="mb-0">
                          <span className="ml-2 text-sm">
                            {moment(assessment.updated_at).format("llll")}
                          </span>
                        </h4>
                        <small className="ml-2">
                          {" "}
                          {getStatusLabel(assessment.status)}
                        </small>
                      </div>
                    </Media>
                  </th>
                  <td className="text-right">
                    {assessment.status === "draft" && (
                      <Button
                        color=""
                        onClick={() => confirmDelete(assessment.id)}
                        size="sm"
                      >
                        Delete
                      </Button>
                    )}
                    <Button
                      color="primary"
                      to={"/app/assessments/" + assessment.id}
                      tag={Link}
                      size="sm"
                    >
                      View
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="text-center">
          <img alt="..." src={require("assets/img/icons/empty.png")} />
        </div>
      )}
    </Card>
  );
}
