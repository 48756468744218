import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import Header from "components/Headers/Header.js";
import Notification from "components/Notification";

import { useAppContext } from "contexts/AppContext";
import useMyLevel from "hooks/useMyLevel";

import AssessmentsHistory from "./AssessmentsHistory";

import AssessmentOverview from "./AssessmentOverview";

function AssessmentsPage() {
  const { user, account, loading: contextLoading } = useAppContext();
  const { assessment, loading, error } = useMyLevel(account?.id, user?.id);

  if (!contextLoading && !account) {
    return (
      <>
        <Header />
        <Notification
          title="You don't have an account"
          message="Please ask your organization admin to send you an invitation"
        />
      </>
    );
  }

  return (
    <>
      <Header />
      <Container className="mt--1" fluid>
        <Row className="mb-2">
          <Col className="text-right">
            <Button
              color="primary"
              href="#save"
              to={"/app/assessments/new"}
              tag={Link}
              size="md"
            >
              Start new assessment
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <AssessmentOverview
              user={user}
              assessment={assessment}
              loading={loading}
              error={error}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <AssessmentsHistory user={user} account={account} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AssessmentsPage;
