import React from 'react';
import { Link } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Collapse
} from 'reactstrap';

import { ACCOUNT_COMPANY_PATH, ACCOUNT_USERS_PATH, ACCOUNT_MANAGERS_PATH } from 'constants/paths';
import { CompanyAvatar } from 'components/Avatars/CompanyAvatar';

function AccountSidebar ({ company }) {
  return (
      <Card className="shadow">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
              {
                    company &&
                      <div className="align-items-center media">
                        <CompanyAvatar name={company.name} photo={company.logo_url} />
                        <h2 className="mb-0 ml-3"> {company.name}</h2>
                    </div>
              }
              </div>
            </Row>
          </CardHeader>
          <CardBody>
          <Navbar className="navbar-vertical bg-white">
          <Collapse navbar isOpen={true}>
            <Nav className="mb-md-3" navbar>
              <NavItem>
                <NavLink to={ACCOUNT_COMPANY_PATH} tag={Link} className="pb-3">
                  <i className="ni ni-building" />
                  <span className="nav-link-text">Company</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={ACCOUNT_USERS_PATH} tag={Link} className="pb-3">
                  <i className="ni ni-single-02" />
                  <span className="nav-link-text">Members</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={ACCOUNT_MANAGERS_PATH} tag={Link} className="pb-3">
                  <i className="ni ni-single-02" />
                  <span className="nav-link-text">Organization</span>
                </NavLink>
              </NavItem>
            </Nav>
            </Collapse>
            </Navbar>
          </CardBody>
      </Card>
  );
}

export default AccountSidebar;
