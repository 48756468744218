import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

import { FRAMEWORK_PATH, TITLES_PATH } from "constants/paths";
import { titles } from "constants/data";

import Header from "components/Headers/Header.js";
import Notification from "components/Notification";

import TitleSelector from "./TitleSelector";
import TitleDetail from "./TitleDetail";

import useMyLevel from "../../hooks/useMyLevel";
import { useAppContext } from "contexts/AppContext";

function Titles() {
  const [selected, setSelected] = useState(null);

  const { user, account, loading: contextLoading } = useAppContext();
  const { assessment } = useMyLevel(account?.id, user?.id);

  if (!contextLoading && !account) {
    return (
      <>
        <Header />
        <Notification
          title="You don't have an account"
          message="Please ask your organization admin to send you an invitation"
        />
      </>
    );
  }
  return (
    <>
      <Header />
      <Container className="mt--1" fluid>
        <Row className="align-items-center mb-3">
          <div className="col d-flex">
            <Button
              outline
              color="secondary"
              className="border-0"
              to={FRAMEWORK_PATH}
              tag={Link}
            >
              Growth Framework
            </Button>
            <div
              className="ml-2 mr-2"
              style={{ width: "1px", backgroundColor: "#9ea9ef" }}
            ></div>
            <Button
              outline
              color="primary"
              className="border-0"
              to={TITLES_PATH}
              tag={Link}
            >
              Titles
            </Button>
          </div>
        </Row>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Titles</h3>
                  </div>
                  <div className="col"></div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row style={{ padding: "20px" }}>
                  <TitleSelector
                    titles={titles}
                    selectedTitle={selected}
                    currentTitle={"none"}
                    onSelectTitle={(title) => setSelected(title)}
                  />
                </Row>
                <div
                  style={{
                    padding: "20px",
                    marginTop: "15px",
                    borderTop: "1px solid #f2f2f2",
                  }}
                >
                  <TitleDetail
                    title={selected}
                    skills={assessment?.progression}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Titles;
