import React from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";

import UserHeader from "components/Headers/UserHeader.js";
import { FormInput } from "components/FormInput";
import FormSelect from "components/FormSelect";
import Message from "components/Message";

import BasicProfile from "./BasicProfile";

import useProfile from "../../hooks/useProfile";
import useMembers from "../../hooks/useMembers";

import { useAppContext } from "contexts/AppContext";

import { getTitlesOptions } from "constants/data";

function Profile() {
  const { user, account } = useAppContext();
  const { profile, success, error, saving, updateProfile } = useProfile(user?.id);
  const { users } = useMembers(account?.id, user?.id , false);
  const { register, handleSubmit, control } = useForm();

  const managers = users?.map((user) => ({value: user.id, label: user.name}));
  const titles = getTitlesOptions();

  const onSubmit = (data) => {
    updateProfile(data);
  };

  if (!profile) {
    return null;
  }

  return (
    <>
      <UserHeader user={profile} />
      <Message
        success={success && "Changes have been saved!"}
        error={error && "Oops. Something went wrong!"}
      />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1 mb-5 mb-xl-0" xl="4">
            <BasicProfile profile={profile} />
          </Col>
          <Col className="order-xl-2" xl="8">
            <Card className="bg-secondary shadow">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My account</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button color="primary" type="submit" size="md" disabled={saving}>
                        Save
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            Display Name
                          </label>
                          <FormInput
                            defaultValue={profile?.name || ""}
                            {...register("name", { required: true })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="phone">
                            Phone Number
                          </label>
                          <FormInput
                            defaultValue={profile?.phone || ""}
                            {...register("phone")}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="code">
                            Short Code
                          </label>
                          <FormInput
                            defaultValue={profile?.code || ""}
                            {...register("code")}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                      <FormGroup>
                          <label className="form-control-label" htmlFor="code">
                            Position
                          </label>
                          <FormSelect control={control}
                            name="position"
                            options={titles}
                            defaultValue={profile?.position || ""}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Description */}
                  <h6 className="heading-small text-muted mb-4">Bio</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <FormInput
                        placeholder="A few words about you ..."
                        rows="4"
                        type="textarea"
                        defaultValue={profile?.bio || ""}
                        {...register("bio")}
                      />
                    </FormGroup>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Manager</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <FormSelect control={control}
                              name="manager"
                              options={managers}
                              defaultValue={profile?.manager || ""}
                            />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Profile;
