import React from "react";

import StreamCard from "./StreamCard";

function TrackSelector({
  streams,
  track,
  selectedIndex,
  setSelectedIndex,
  displayTracksOnly,
  enableToggle,
}) {
  function toggleTrack(index) {
    if (!enableToggle) {
      setSelectedIndex(index);
      return;
    }
    if (selectedIndex === index) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
    }
  }

  return (
    <table className="track-selector-table">
      <style>{`
          .track-selector-table {
            border-collapse: separate;
            width: 100%;
            border-spacing: 3px;
            padding-bottom: 20px;
            margin-bottom: 20px;
            margin-left: -3px;
          }
          .track-selector-value {
            color: white;
            width: 4rem;
            height: 3rem;
            text-align: center;
            background: #eee;
            font-size: 12px;
            border-radius: 3px;
            cursor: pointer;
          }
        `}</style>
      <tbody>
        {!displayTracksOnly && (
          <tr>
            <td colSpan={8} className="text-center">
              <div className="text-info text-center">SMART</div>
              <p className="text-sm text-muted mb-1">
                Technical Impact &amp; Influence
              </p>
            </td>
            <td colSpan={8} className="text-center">
              <div className="text-warning text-center">HEALTY</div>
              <p className="text-sm text-muted mb-1">
                Organizationnal Impact &amp; Influence{" "}
              </p>
            </td>
          </tr>
        )}
        {!displayTracksOnly && (
          <tr>
            {streams?.map((stream) => (
              <td key={stream.id} colSpan={stream.tracks.length}>
                <StreamCard stream={stream} />
              </td>
            ))}
          </tr>
        )}
        <tr>
          {streams?.map((stream, i) =>
            stream?.tracks?.map((t, j) => {
              return (
                <td
                  key={"value-" + t.id}
                  className="track-selector-value"
                  style={{
                    border:
                      "4px solid " +
                      (t.id === track?.track?.id ? "#000" : stream.color),
                    background: stream.color,
                  }}
                  onClick={() => toggleTrack(i * stream.tracks.length + j)}
                >
                  {t.displayName}
                </td>
              );
            })
          )}
        </tr>
      </tbody>
    </table>
  );
}

export default TrackSelector;
